/** @typedef {Object.<string, string>} Dict */

import Result from "@/models/Result";
import Vue from "vue";

const state = {
    selected_attr: {name: null, axis: null},
    extremes: {},
    attr_axes: {},
    deformation: 0,
    probe_mode: false,
    probe_list: [],
    probe: null,
    probe_point: null,
    actual_result: {},
    legend_interval: {min: 0, max: 1},
    results: [],
}

const getters = {
    selected_attr: s => s.selected_attr,
    results: state => state.results,
    actual_result: state => state.actual_result,
    deformation_attr: state => {
        if ('Displacement' in state.extremes) {
            return 'Displacement'
        }
        if (state.selected_attr.name && state.selected_attr.name.indexOf('displacement') !== -1) {
            return state.selected_attr.name
        }
        return null
    },
    frequency: state => {
        let frequency_re = /\(([^()]*) Hz\)/;
        if (state.selected_attr.name) {
            let x = frequency_re.exec(state.selected_attr.name)
            if (x && x.length === 2) {
                let frequency = Number(x[1])
                if (frequency && frequency < 20) return frequency
            }
        }
        return 0
    },
    frequences: state => {
        let frequences = []
        let frequency_re = /Mode ([0-9]*) \(([^()]*) Hz\)/;
        Object.keys(state.attr_axes).forEach((name) => {
            let x = frequency_re.exec(name)
            if (x && x.length > 1) {
                frequences.push({index: Number(x[1]), fr: Number(x[2])})
            }
        })
        frequences.sort((f1,f2) => {
          if (f1.index > f2.index) return 1;
          if (f1.index === f2.index) return 0;
          if (f1.index < f2.index) return -1;
        });
        return frequences
    },
    selected_attr_eq: s => attr =>
        (!('name' in attr) || s.selected_attr.name === attr.name)
        && (!('axis' in attr) || s.selected_attr.axis === attr.axis),
    extremes: state => {
        return state.extremes
    },
    legend_interval: state => state.legend_interval,
    current_extremes: state => {
        let {extremes, selected_attr} = state
        if (selected_attr.name && selected_attr.axis) return {
            min: extremes[selected_attr.name][selected_attr.axis].min.value,
            max: extremes[selected_attr.name][selected_attr.axis].max.value
        }
        return {min: 0, max: 1}
    },
    attr_axes: state => {
        return state.attr_axes
    },
    deformation: state => state.deformation,
    probe_mode: s => s.probe_mode,
    probe: s => s.probe,
    probe_point: s => s.probe_point,
    probe_list: s => s.probe_list,
};

const mutations = {

    set_results(state, results) {
        state.results = results.map(data => new Result(data))
    },

    set_actual_result(state, data) {
        state.actual_result = new Result(data)
    },

    set_legend_interval(state, {min, max}) {
        if (!isNaN(min)) Vue.set(state.legend_interval, 'min', min)
        if (!isNaN(max)) Vue.set(state.legend_interval, 'max', max)
    },

    set_probe(state, value) {
        state.probe = value
    },
    set_probe_point(state, value) {
        state.probe_point = value
    },
    append_probe_list(state) {
        state.probe_list.push(state.probe)
    },

    purge_probe_list(state) {
        state.probe_list = []
    },

    delete_probe_from_list(state, index) {
        state.probe_list.splice(index, 1)
    },

    set_result_attrs(state, result) {
        let axes = {}
        let extremes = {}
        for (let attr in result) {
            if (['Node ID','Node ID','Strength Properties', 'Material ID', 'Contact Gap Node', 'Contact Status Node', 'Contact Stress Node', 'Contact nodal force'].includes(attr))
                continue

            axes[attr] = result[attr].axes
            extremes[attr] = result[attr].extremes
        }

        state.attr_axes = axes
        state.extremes = extremes
    },

    select_attr(state, attr) {
        if (getters.selected_attr_eq(state)(attr)) return

        let selected_attr = {}

        if ('name' in attr) {
            selected_attr.name = attr.name
            selected_attr.axis = 'axis' in attr ? attr.axis : state.attr_axes[attr.name][0]
        } else {
            selected_attr.name = state.selected_attr.name
            selected_attr.axis = 'axis' in attr ? attr.axis : state.selected_attr.axis
        }
        state.selected_attr = selected_attr
        if (selected_attr.name && selected_attr.axis){
            if(selected_attr.name.includes('Safety Factor')){
                state.legend_interval = {
                    min: 10,
                    max: 1
                }
            }else{
                state.legend_interval = {
                    min: state.extremes[selected_attr.name][selected_attr.axis].min.value,
                    max: state.extremes[selected_attr.name][selected_attr.axis].max.value
                }
            }
        }
    },
    set_deformation(state, value) {
        state.deformation = value
    },

    set_probe_mode(state, value) {
        state.probe_mode = value
    },
};

const actions = {
    load_result(context) {
        return context.getters['net/fetchJson']('result_api')
            .then(data => {
                context.commit('set_task', data)
                context.commit('net/update_url_replacement', {pid: data.project.id})
            })
    },

}


export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions
};

