import BaseModel from '@/models/Model';
import EntityTypes from '@/models/EntityTypes';


export default class Task extends BaseModel {
    /** @param {Object=} data */
    constructor(data) {
        super(data)
    }

    update(data){
        super.update(data)

        /** @type {string} */
        this.title = this.column(String, 'title', data)

        /** @type {string} */
        this.description = this.column(String, 'description', data)

        /** @type {Date} */
        this.created = this.column(Date, 'created', data)

        /** @type {Date} */
        this.modified = this.column(Date, 'modified', data)

        /** @type {string} */
        this.origin = this.column(String, 'origin', data)

        /** @type {string} */
        this.external_id = this.column(String, 'external_id', data)

        /** @type {Array} */
        this.filelist = this.column(Array, 'filelist', data)

        /** @type {Object.<Entity>} */
        this.conditions = {}

        if (data.conditions) {
            for (let id in data.conditions) {
                this.conditions[id] = new (EntityTypes[data.conditions[id].entity_type])(data.conditions[id])
            }
        }

        /** @type {Object} */
        this.properties = this.column(Object, 'properties', data)

        /** @type {string} */
        this.unit_system = this.column(String, 'unit_system', data, 'si')

        /** @type {number} */
        this.scale = this.column(Number, 'scale', data, 1)

        /** @type {Date} */
        this.deleted = this.column(Date, 'deleted', data, null)
    }

        /**
     * @param {RegExp|string} re
     * @param {string=} opt_flags
     * @return {boolean}
     */
    matched(re, opt_flags) {
        re instanceof RegExp || (re = new RegExp(re, opt_flags || 'ig'));
        return re.test(this.title);
    }
}