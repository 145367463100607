Object.assign(Function.prototype, {
    /**
     * @param {*} scope
     * @param {*[]=} args
     */
    do(scope, args) {
        return this.call(
            scope,
            ...Array.isArray(args) && arguments.length < 3
                ? args
                : Array.prototype.slice.call(arguments, 1),
        );
    },

    /**
     * @param {string} method
     * @param {function(*): function(*): *} replacer
     */
    replacePrototype(method, replacer) {
        (p => p[method] = replacer(p[method]))(this.prototype);
    },
});
