import './array';


Object.assign(Object, {
    /**
     * @param {Object} src
     * @param {...string} arguments excluded keys
     * @return {Object}
     */
    clone(src) {
        const dst = {};
        const excluded = Array.slice(arguments, 1);

        this.forEach(src, (value, key) => {
            if (0 <= excluded.indexOf(key)) {
                return;
            }

            switch (true) {
                case Array.isArray(value):
                    value = value.map(
                        v => this.isObject(v) ? this.clone(v) : v,
                    );
                    break;

                case this.isObject(value):
                    value = this.clone(value);
                    break;
            }

            dst[key] = value;
        });

        return dst;
    },

    /**
     * @param {Object} o
     * @param {...Object} arguments redefined properties
     * @return {Object}
     */
    copy(o) {
        return this.assign({}, ...arguments);
    },

    /**
     * @param {Object} o
     * @param {(function(*, string=, number=):boolean)=} opt_cb
     * @return {Object}
     */
    filter(o, opt_cb) {
        const ret = {};

        opt_cb || (opt_cb = v => !!v);

        this.forEach(o, (v, k, i) => {
            opt_cb(v, k, i) && (ret[k] = v);
        });

        return ret;
    },

    /**
     * @param {Object} o
     * @param {function(*=, string=, number=):?boolean} cb
     */
    forEach(o, cb) {
        this.keys(o).forEach((k, i) => cb(o[k], k, i));
    },

    /**
     * @param {*} v
     * @return {boolean}
     */
    isObject(v) {
        return v instanceof this;
    },

    /**
     * @param {Object} o
     * @param {function(*, string=, number=)} cb
     * @return {Object}
     */
    map(o, cb) {
        const ret = {};
        cb || (cb = v => v);
        this.forEach(o, (v, k, i) => {
            ret[k] = cb(v, k, i);
        });

        return ret;
    },

    /**
     * @param {Object} o
     * @return {number}
     */
    size(o) {
        return this.keys(o).length;
    },
});
