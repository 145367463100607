import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'

import en from './lang/en';
import ru from './lang/ru';
import zh from './lang/zh';

Vue.config.productionTip = false

Vue.use(VueI18n)


const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages: { en, zh, ru},
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('main')
