import {v4 as uuid} from "uuid";
import {isArray, isBoolean, isDate, isNumber, isObject, isString, isUndefined} from "lodash";


/** @abstract */
export default class BaseModel {
    constructor(data = {}) {
        this.update(data)

    }

    update(data) {

        let eid = uuid()


        if('id' in data){
            /** @type {string} */
            this.id = this.column(String, 'id', data, eid)
            /** @type {string} */
            this.uuid = this.column(String, 'id', data, eid)
        }else{
            /** @type {string} */
            this.id = this.column(String, 'uuid', data, eid)
            /** @type {string} */
            this.uuid = this.column(String, 'uuid', data, eid)
        }

    }



    /**
     *
     *
     * @param {Class} Type
     * @param {string} key
     * @param {object} data
     * @param {*?} def
     * @returns {*}
     */
    column(Type, key, data, def){
        if ((key in this) && !(key in data))
            return this[key]

        const value = data[key]

        switch (Type) {
            case String:
                return isString(value) ? value : isUndefined(def) ? '' : def
            case Number:
                return isNumber(value) ? value : isUndefined(def) ? 0 : def
            case Boolean:
                return isBoolean(value) ? value : isUndefined(def) ? false : def
            case Array:
                return isArray(value) ? value : isUndefined(def) ? [] : def
            case Object:
                return isObject(value) ? value : isUndefined(def) ? {} : def
            case Date:
                return isDate(value) ? value : Date.parse(value) ? new Date(value) : isUndefined(def) ? new Date() : def
            default:
                return value instanceof Type ? value : isUndefined(def) ? null : def
        }
    }
}
