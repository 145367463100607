import Task from '@/models/Task';
import BaseModel from '@/models/Model';


export default class Project extends BaseModel {
    /** @param {Object=} data */
    constructor(data) {
        super(data);
    }

    /** @param {Object} data */
    update(data) {
        super.update(data)

        /** @type {string} */
        this.title = this.column(String, 'title', data)
        /** @type {string} */
        this.description = this.column(String, 'description', data)
        /** @type {Date} */
        this.created = this.column(Date, 'created', data)
        /** @type {Date} */
        this.modified = this.column(Date, 'modified', data)
        /** @type {string} */
        this.origin = this.column(String, 'origin', data)
        /** @type {string} */
        this.external_id = this.column(String, 'external_id', data)
        /** @type {string} */
        this.unit_system = this.column(String, 'unit_system', data)
        /** @type {boolean} */
        this.public = this.column(Boolean, 'public', data)
        /** @type {boolean} */
        this.readable = this.column(Boolean, 'readable', data)
        /** @type {boolean} */
        this.readonly = this.column(Boolean, 'readonly', data)
        /** @type {boolean} */
        this.writeable = this.column(Boolean, 'writeable', data)
        /** @type {Object} */
        this.access = this.column(Object, 'access', data)

        /** @type {Task[]} */
        this.tasks = []
        if (data.tasks){
            this.tasks = data.tasks.map(task => new Task(task))
        }

    }

    /** @param {Dictionary} taskProps */
    addTask(taskProps) {
        this.tasks.push(new Task(taskProps));
    }

    /**
     * @param {string} uid
     * @return {?Task}
     */
    findTask(uid) {
        let found;

        this.tasks.forEach(item => {
            if (item.id === uid) {
                found = item;
                return false;
            }
        });

        return found;
    }

    /**
     * @param {RegExp|string} re
     * @param {string=} opt_flags
     * @return {boolean}
     */
    matched(re, opt_flags) {
        re instanceof RegExp || (re = new RegExp(re, opt_flags || 'ig'));
        return re.test(this.title) || this.tasks.some(c => c.matched(re, opt_flags));
    }
}

