import BaseModel from '@/models/Model';


export default class User extends BaseModel {
    /** @param {Object=} data */
    constructor(data) {
        super(data)
    }

    get auth(){
        return !this.anonim
    }

    update(data){
        super.update(data)

        /** @type {string} */
        this.email = this.column(String, 'email', data)
        /** @type {string} */
        this.name = this.column(String, 'name', data)

        /** @type {boolean} */
        this.confirm = this.column(Boolean, 'confirm', data)
        /** @type {boolean} */
        this.survey = this.column(Boolean, 'survey', data)
        /** @type {boolean} */
        this.spam_flag = this.column(Boolean, 'spam_flag', data)
        /** @type {boolean} */
        this.superuser = this.column(Boolean, 'superuser', data)
        /** @type {boolean} */
        this.anonim = this.column(Boolean, 'anonim', data)

        /** @type {Date} */
        this.created = this.column(Date, 'created', data)
        /** @type {Date} */
        this.modified = this.column(Date, 'modified', data)
        /** @type {Date} */
        this.last_activity = this.column(Date, 'last_activity', data)

        /** @type {string} */
        this.company = this.column(String, 'company', data)
        /** @type {string} */
        this.country = this.column(String, 'country', data)

        /** @type {boolean} */
        this.is_student = this.column(Boolean, 'is_student', data)

        /** @type {string} */
        this.origin = this.column(String, 'origin', data)
        /** @type {string} */
        this.external_id = this.column(String, 'external_id', data)
        /** @type {string} */
        this.status = this.column(String, 'status', data)

        /** @type {Object} */
        this.information = this.column(Object, 'information', data)
        /** @type {string} */
        this.lang = this.column(String, 'lang', data)
        /** @type {string} */
        this.unit_system = this.column(String, 'unit_system', data)
        /** @type {string} */
        this.balance = this.column(Number, 'balance', data)
    }
}