export default class {
    /** @param {string=} opt_keyPrefix */
    constructor(opt_keyPrefix) {
        this.prefix_ = opt_keyPrefix || 'stored';
    }

    /** @return {Promise<?Storage>} */
    get storage() {
        return Promise.resolve(window.localStorage);
    }

    /**
     * @param {string} key
     * @return {Promise<?string>}
     */
    restore(key) {
        return this.storage.then((storage) =>
            storage ? storage.getItem(`${this.prefix_}:${key}`) : Promise.reject(storage)
        );
    }

    /**
     * @param {string} key
     * @param {string|Promise<string>} item
     */
    store(key, item) {
        this.storage.then(storage => storage && (
            item instanceof Promise ? item : Promise.resolve(item)
        ).then(item => storage.setItem(`${this.prefix_}:${key}`, item)));
    }
}
