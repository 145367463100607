export default {
    lang: 'en',

    header: {
        login: 'Login or register',
        projects: 'Projects',
        logout: 'Logout',
        user_settings: 'User Settings',
        video: 'Watch Video Tutorial'
    },
    footer: {
        help: 'Help',
        manual: 'Manual',
        tutorial: 'Tutorial',
        examples: 'Examples',
        faq: 'FAQ',
        contact: 'Contact us'
    },
    home: {
        title: {
            headline: 'Next generation cloud CAE service',
            description: 'Calculation of loads, strength and thermal elasticity online',
        },
        button: {
            try_for_free: 'Try for free',
        },
        what_types: {
            title: 'What kind of cases can be calculated by the service?',
            structural_title: 'Structural',
            structural_description: 'Calculate the strength of the model under various loads.',
            thermoelastic_title: 'Thermoelastic',
            thermoelastic_description: 'Calculate the thermoelastisity of the model.',
            modal_title: 'Modal',
            modal_description: 'Calculate modal frequencies and forms of vibrations of the model.',
        },
        simple: {
            simple_touse_and_understand: 'Simple to use and understand',
            promo_title_text: `Prove.design simulation process allows you to view and test instantly structural performance alternatives in order to reach your design goals`,
            title_1: '3D Import',
            text_1: `The 3D model is imported into the service, a special table specifies the material from which the model is made (you can select from the library), what forces act on it. The computational grid will be built automatically.`,
            title_2: 'Mode selection',
            text_2: 'You can select static load, heated load, or modal analysis.',
            title_3: 'Result',
            text_3: `The output will be a model, on which the points of minimum and maximum load and deformation of the model will be visible.`,
            how_does_it_works: 'How does it works?',
            text_4: 'Prove.Design is a cloud service for constructors, which helps calculate the strength of the designed model.',
            text_5: 'All you need to use it is 3D model created in one of the popular CAD packages',
            start_production_with: 'Start producing your best work with Prove.design',
            click_to_start: 'Click below to get started for free',
            free_trial: 'Free Trial',
        }
    },
    login: {
        title: 'Let\'s start',
        status_text: 'Enter an e-mail and password for a new or existing account',
        submit: 'Next',
    },
    survey: {
        title: 'Tell us about yourself',
        status_text: 'We want to know our customers as best we can in order to further improve our service. Fill out the survey below and get a thousand free Estimated Seconds for your projects!',
        name: 'What is your name?',
        privacy_confirm_1: 'I agree with',
        privacy_confirm_2: 'Personal data processing policy.',
        country: 'Where are you from:',
        company: 'Where do you work or study:',
        is_student: 'I am student or teacher',
        phone: 'Phone number:',
        spam_flag: 'Receive emails from our service. You can unsubscribe at any time.',
        submit: 'Next',
    },
    unconfirmed: {
        welcome: 'Welcome to Prove.Design!',
        logout:'Logout',
        description1: 'You have not confirmed your account. Please check your email ',
        description2: '(and your spam folder) - you should have received an email with a confirmation link.',
        didnt_get:'Didn\'t get the email?',
        resend: 'Resend'
    },
    projects: {
        new_project: 'New Project',
        search: 'Search for projects',
        task: 'task',
        tasks: 'tasks',
        edit: 'Edit project…',
        share: 'Share project…',
        delete: 'Delete project…',
        no_project: 'No projects created',
        loading: 'Loading projects...',
        modal: {
            create: {
                create_task: 'Create task',
                uploaded: 'Uploaded',
                title: 'Title',
                source: 'Source',
                unit_system: 'Unit length',
                calc_unit_system: 'Calculation unit system of',
                cancel: 'Cancel',
                create: 'Create'
            },
            settings: {
                project_settings: 'Project settings',
                title: 'Title',
                description: 'Description',
                public: 'Public',
                units: 'Units',
                update: 'Update'
            },
            share: {
                project_users: 'Project users',
                user_email: 'user e-mail',
                read_only: 'Read only',
                share: 'Share',
                unshare: 'Unshare'
            },
            edit_task: {
                edit_task: 'Edit task',
                title: 'Title',
                source: 'Source',
                cancel: 'Cancel',
                update: 'Update'
            }
        }
    },
    tasks: {
        upload: 'Upload new CAD model ',
        edit_task: 'Edit task…',
        copy_task: 'Copy task…',
        delete_task: 'Delete task…',
        generating_preview: 'Generating preview...'

    },
    profile: {
        header: {
            user_settings: 'User settings',
            logout: 'Log out'
        },
        captions: {
            'account': 'Account',
            'password': 'Password',
            'balance': 'Balance',
        },
        name: 'Name',
        company: 'Company',
        phone: 'Phone',
        country: 'Country',
        preferred_unit_system: 'Preferred unit system',
        language: 'Language',
        subscribe_to_newsletter: 'Subscribe to newsletter',
        current_password: 'Current password',
        new_password: 'New password',
        update: 'Update'
    },
    workbench: {
        modemenu: {
            geometry_and_mesh: 'Geometry & Mesh',
            material_and_bcs: 'Materials & BCs',
            view_result: 'View Results',
            building_geometry: 'Building Geometry...',
            rebuild_geometry: 'Rebuild Geometry',
            build_geometry: 'Build Geometry',
            meshing: 'Meshing...',
            rebuild_mesh: 'Rebuild Mesh',
            build_mesh: 'Build Mesh',
            calculate: 'Calculate...',
            restart_calculation: 'Restart Calculation',
            start_calculation: 'Start Calculation',
            set_material_and_bc: 'Set Material & BC to Calculate',
            zero_balance: 'Your balance is zero. Please, top up your account',
            analysis_type: {
                Static: 'Static',
                Modal: 'Modal',
                Buckling: 'Buckling'
            }
        },
        statistics: {
            task_statistics: 'Task Statistics',
            volume: 'Volumes',
            surface: 'Surfaces',
            curve: 'Curves',
            vertex: 'Vertexes',
            coarseness: 'Coarseness',
            node: 'Nodes',
            element: 'Elements',
            bounding_box: "Size",
            total_volume: "Total Volume",
            total_area: 'Total Area',
            size_x: 'Size X',
            size_y: 'Size Y',
            size_z: 'Size Z'
        },
        build_button: {
            coarseness: 'Coarseness',
            set_result_name: 'Set Result Name',
            analysis_type: 'Analysis Type',
            elasticity: 'Elasticity',
            plastisity: 'Plastisity',
            heattrans: 'Heattrans',
            preload_model: 'Preload model',
            number_of_eigen_frequencies: 'Number of eigen frequencies',
            number_of_buckling_modes: 'Number of buckling modes'
        },
        tree: {
            material: {
                add_material: 'Add Material',
                materials: 'Materials',
                markers: 'Markers',
                import_from_material_library: 'Import from material library',
            },
            bcs: {
                add_boundary_condition: 'Add Boundary Condition',
                fixed: 'Fixed',
                displacement: 'Displacement',
                pressure: 'Pressure',
                gravity: 'Gravity',
                angular_velocity: 'Angular Velocity',
                distributed_force: 'Distributed Force',
                temperature: 'Temperature',
                convection: 'Convection',
                heat_flux: 'Heat Flux',
                boundary_conditions: 'Boundary Conditions'
            },
            select: {
                surface: 'Surface',
                node: 'Node',
                volume: 'Volume',
                markers: 'Markers',
                apply: 'Apply',
                si: 'SI',
                cgs: 'CGS',
                mmns: 'MMNS',
                fps: 'FPS',
                ips: 'IPS'
            },
            result: {
                deformation: 'Deformation',
                values: 'Values',
                drop: 'Drop',
                take: 'Take',
                probes: 'Probes',
                hide: 'Hide',
                show: 'Show',
                safety_factor: 'Safety Factor',
                little_stress: 'Little stress',
                significant_stress: 'Significant stress!',
                critical_stress: 'Critical stress!'
            },
        },
        topmenu: {
            mesh: 'Mesh',
            inside: 'Inside',
            scale: 'Scale',
            axes: 'Axes',
            legend: 'Legend',
            console: 'Console',
            internal_curves: 'Internal Edges'
        },
        lut: {
            Displacement: 'Displacement',
            Magnitude: 'Magnitude',
            Mises: 'Mises',
            'Principal 1': 'Principal 1',
            'Principal 2': 'Principal 2',
            'Principal 3': 'Principal 3',
            'Yield strength': 'Yield strength',
            'Ultimate strength': 'Ultimate strength',
            'Mass acceleration': 'Mass acceleration',
            'Thermal Stress': 'Thermal Stress',
            'Thermal Strain': 'Thermal Strain',
            'Heat Flux': 'Heat Flux',
            'Angular Velocity': 'Angular Velocity',
            'Temperature': 'Temperature',
            'Yield': 'Yield strength',
            'Ultimate': 'Ultimate strength',
            'Elastic Strain': 'Elastic Strain',
            'Plastic Strain': 'Plastic Strain',
            'Safety Factor 1-Principal': 'Safety Factor 1-Principal',
            'Safety Factor Mises': 'Safety Factor Mises',
            'Local Coordinate System Vector X': 'Local Coordinate System Vector X',
            'Local Coordinate System Vector Y': 'Local Coordinate System Vector Y',
            'Local Coordinate System Vector Z': 'Local Coordinate System Vector Z',
            'Principal stress vector 1': 'Principal Stress Vector 1',
            'Principal stress vector 2': 'Principal Stress Vector 2',
            'Principal stress vector 3': 'Principal Stress Vector 3',
            Strain: 'Strain',
            'Strength Properties': 'Strength Properties',
            Stress: 'Stress',
            X: 'X',
            Y: 'Y',
            Z: 'Z',
            XX: 'XX',
            YY: 'YY',
            ZZ: 'ZZ',
            XY: 'XY',
            YZ: 'YZ',
            XZ: 'XZ',
            value: 'Value'
        }
    },
    help: {
        to_contact_us: 'To contact us',
        write_to: 'write to',
        or_click_button: 'Or click on the button in the lower right corner',
        manual: {
            user_manual: "User's manual",
            project_settings: 'Project Settings',
            model_settings: 'Model settings',
            what_is_on_the_screen: 'What is on the screen',
            title: {
                title_1: 'Switch Panel',
                title_2: 'Model orientation',
                title_3: 'Logs Window',
                title_4: 'Model Information',
                title_5: 'Configuring the Finite Element Mesh',
                title_6: 'Result output scale',
                title_7: 'Adding material',
                title_8: 'Adding boundary conditions',
                title_9: 'Start of calculation',
                title_10: 'After calculation',
                title_11: 'How to change a project',
                title_12: 'How to share a project',
                title_13: 'How to delete a project',
                title_14: 'How to add a model to a project',
                title_15: 'How to rename a model',
                title_16: 'How to copy a model',
                title_17: 'How to move a model from one project to another',
                title_18: 'How to delete a model',

            },
            text: {
                text_1: 'The switch panel at the top of the screen allows you to display only the necessary information. The user can disable those tools that he does not use.',
                text_2: 'The switches are responsible for the following actions',
                text_3: '"Mesh" - Enables and disables the display of the finite element mesh on the model.',
                text_4: '"Inside" - Enables and disables transparency of model textures.',
                text_5: '"Scale bar" - Enables and disables the display of the external dimensions of the model.',
                text_6: `"Axes" - Enables and disables the widget of the model's position in space.`,
                text_7: '"Legend" - Enables and disables the scale of colors and numerical values of calculation results.',
                text_8: '"Console" - Enables and disables transparency of the background of the logs window.',
                text_9: '"Result" - import files with calculation results, as well as generate and get detailed calculation results in PDF format.',
                text_10: 'You can use the mouse to change the position of the model in space. To do this, click on the model, or the free space next to it and, without releasing the mouse button, pull in the desired direction.',
                text_11: 'The widget in the upper right corner will show the position of the model relative to the main axes.',
                text_12: `The widget can also change the orientation of the model in space. By clicking on the arrows, you can rotate the model relative to the main axes. The rotation takes place in 36-degree increments.
                            To achieve the orthogonal position of the model, you can click on the widget face we need.
                            To increase or decrease the display of the model, you must use the wheel.
                            If necessary, the model position widget can be disabled by clicking the "Axes" button in the top menu.`,
                text_13: 'The logs window stores the history of user interaction with the model. The response from the server, the results and the state of the model will also be displayed in the dialog box. For convenience, the background of the dialog box is transparent, but it can be made explicit by using the "Console" switch in the upper panel.',
                text_14: 'Information about the loaded model can be found in the Geometry & Mesh menu tab',
                text_15: 'You can configure the finite element mesh in the Geometry & Mesh menu tab. Using the slider, you can change the roughness of the mesh. If you click on the "Recalculate mesh" button, the server will recalculate the finite element mesh and display the result on the model.',
                text_16: 'By changing the coarseness of the mesh, you can improve the accuracy of the calculation results or save computational time if the calculation is required to be not very accurate.',
                text_17: 'In result output mode, the value scale associates a color with the numerical value of the results.',
                text_18: 'The fields at the top of the scale allow you to switch the entities of the output results and their directions. Changing the display of calculation results on the model occurs automatically.',
                text_19: 'The maximum and minimum limit values can be limited independently. To do this, enter the maximum required limit in the upper value field. The same should be done with the minimum value in the lower input field. Displaying the results on the model occurs automatically.',
                text_20: 'In the "Materials and boundary conditions" tab, select the "add material" item.',
                text_21: 'You can create any material by filling out the form with the values of the characteristic of the required material. It is also possible to select a material from the library by opening it by clicking on the "Import from material library" button',
                text_22: 'To delete a material, click on the trash can icon to the right of the title.',
                text_23: 'In the "Materials and Boundary Conditions" tab, select the "Add Boundary Condition" item.',
                text_24: 'Select the required BC from the list of suggested ones.',
                text_25: `Select the entity of the BC application (face, volume, point). Select all the required entities of the BC application. It is also necessary to specify the value of the value or movement of the BC. After making the necessary settings, click the "Apply" button.
                            It is possible to add an unlimited number of BCs.`,
                text_26: 'Before starting the calculation, you must select the analysis mode in the form located at the bottom of the tab "Materials and BC".',
                text_27: `A necessary condition for starting the calculation is the addition of material to all volumes of the model, as well as the addition of boundary conditions. Please note that in order to calculate the applied force or to calculate the modal analysis, the prerequisite for starting the calculation is the definition of a restraint model.
                    If all the necessary conditions are met, the "Start Calculation" button will be active. By pressing it, the server will start the calculations.
                    In the dialog box, the steps and results of calculations. After the calculation is completed, the output of the results will occur automatically.`,
                text_28: 'The calculation results can be managed in the "View result" section',
                text_29: 'Navigation through the results of calculations is carried out using the switch in the upper part of the results output tab.',
                text_30: 'You can view the maximum and minimum value of the output data in the next menu. It is also possible to take a value at an arbitrary point in the model. To do this, you need to hold down the "Take Probes" button and put a point on the model in the required place.',
                text_31: 'You can visualize model displacements and deformations using the following tool:',
                text_32: 'At the end of the calculation, you can get a report by clicking the "Result" button at the top of the screen.',
                text_33: 'In addition to the ability to import calculation results, it is possible to obtain a report in PDF format.',
                text_34: '"Show task in whiteboard" will open the model view without UI elements in a new tab.',
                text_35: '"Download Result.vtu" imports a file of convenient use of calculation results on your site.',
                text_36: 'If necessary, change or adjust the material characteristic or HA overlay settings. After adjusting the settings, you must click the "Recalculate result" button.',
                text_37: 'On the projects page, you can create new projects, as well as make changes to existing ones. To create a new project, click the New button in the top left corner.',
                text_38: 'A project is one or several models united according to one principle or another. Projects are used to easily sort your models. To rename projects, click on the three dots to the right of the project name.',
                text_39: 'The called context menu suggests renaming the project, changing access rights, and deleting the project. "Edit project" brings up a modal window.',
                text_40: 'With it, you can rename the project, add a description, make the project /static, and change the priority system of units for the project. After all the necessary changes have been made, it is enough to click the "Update" button so that the changes made are applied to the project.',
                text_41: 'To expand access to the project for other people, you need to click "Share project".',
                text_42: 'In the window that opens, you must specify in the input field the user who will gain access to the project. The "Read only" flag indicates whether the user who gets access can make changes and edit the project. If the flag is enabled, the user who has access will only be able to view the results. The green button in the lower right corner of the modal will send an invitation link to the specified user.',
                text_43: 'To delete projects, click on the three dots to the right of the project name.',
                text_44: '"Delete project" will bring up a modal window asking you to delete the project. If this action is confirmed, the project and everything that it includes will be deleted.',
                text_45: 'You can add a model to the project using the upload card. To do this, you must first download the model to your computer. Click on the download card and select the required file.',
                text_46: 'Please note that the model must match the extensions that Prove.design works with. Alternatively, you can use drag\'n\'drop to load the model and drop the model file into the project area. As a result, a modal model creation window will appear.',
                text_47: 'In it, you can specify a name, as well as select a system of units for the model. By default, the system of units of measure will match the system of units of the project. But if necessary, the system of units of measurement can be set individually for each model. The completion of the creation of the model will be pressing the "Create" button.',
                text_48: 'After adding the model, after some time a "Preview" of the model will be generated, after which it will be possible to open the model in the calculation complex.',
                text_49: 'In the upper right corner of the card, click the ellipsis button. In the context menu that appears, select the Edit Model field. Rename the model in the modal window that appears, then click "apply".',
                text_50: 'In the window that opens, you must specify in the input field the user who will gain access to the project. The "Read only" flag indicates whether the user who gets access can make changes and edit the project. If the flag is enabled, the user who has access will only be able to view the results. The green button in the lower right corner of the modal will send an invitation link to the specified user.',
                text_51: 'In the upper right corner of the card, click the ellipsis button. In the context menu that appears, select the "Copy model" field. A copy of the model will appear in the project with an index&nbsp;(2).',
                text_52: 'If you need to move a model from one project to another, use drag\'n\'drop. To do this, grab the desired model with the cursor and, without releasing the mouse button, transfer the model to the desired project, then release the mouse button. The model will be transferred from one project to another. When transferring, all model calculations will be saved.',
                text_53: 'In the upper right corner of the card, click the ellipsis button. In the context menu that appears, select the "Delete model" field. In the dialog box that appears, you must confirm the action.',

            }
        },
        tutorial: {
          tutorial: 'Tutorial'
        },
        faq: {
            faq: 'FAQ',
            title: {
                title_1: 'What is',
                title_2: 'What calculations can be made?',
                title_3: 'Which CAD formats does',
                title_4: 'support',
                title_5: 'Which calculation engine is used in',
                title_6: 'Who is',
                title_7: 'suitable for',
                title_8: 'What do I need to know about structural analysis?',
                title_9: 'How do I get started with',
                title_10: 'Can I see examples of calculations?',
                title_11: 'How to share calculation results with colleagues or clients?',
                title_12: 'How can I get the calculation results?',
                title_13: 'Where can I read the user manual?',
                title_14: 'How to contact us?',
            },
            text: {
                text_1: 'is a cloud-based CAE service. With it, you can calculate the mechanical analysis of your engineering solutions.',
                text_2: 'allows you to perform static stress calculations, as well as modal and thermostatic analyzes.',
                text_3: 'What benefits does',
                text_4: 'provide',
                text_5: 'Does not require software to be installed on your device.',
                text_6: 'You don\'t need a high performance device. All calculations are made on powerful servers. For comfortable use, you only need a stable Internet connection.',
                text_7: 'You can perform calculations, as well as view the results of calculations from different devices. All you have to do is grant access rights to the desired project.',
                text_8: 'Convenient and intuitive interface adapted for use with mobile devices with small screen sizes.',
                text_9: 'The service supports the most common CAD formats such as .stp, .sat, .stl, .igs.',
                text_10: 'Prove.design uses the calculation core of the',
                text_11: 'CAE Fidesys software package.',
                text_12: 'Our service is suitable for those who need to make simple calculations to analyze the characteristics of models. For example, if you need to calculate for static stresses a part that you have designed for printing on a 3D printer. Also,',
                text_13: 'is suitable for compiling reports and articles, for performing educational work, as a demonstration and training materials, etc.',
                text_14: 'We have created a streamlined modeling process to minimize the required knowledge of structural analysis. The streamlined workflow includes intelligent automation based on project settings to automate routine operations. The process consists of simple steps',
                text_15: 'Create case (loads model, meshes and sets material properties based on project default values)',
                text_16: 'Sets boundary conditions (loads and restraints)',
                text_17: 'Runs analysis, and',
                text_18: 'Reviews results.',
                text_19: 'For more information, please refer to the technical documentation, tutorials and examples provided on the site.',
                text_20: 'To get started, just register. Even if you don\'t have any CAD models yet, you can explore our service using the built-in model library.',
                text_21: 'You can get acquainted with examples of calculations in the section of technical documentation, links to which are located in the footer of the site. Also in this section are educational videos. We have tried to describe in detail the nuances of performing the analysis so that you can quickly start checking your designs.',
                text_22: 'In the project settings you can find a form to grant access to other users. To do this, you must specify the e-mail of the user to whom you want to grant access. Anyone who receives the letter and clicks on the link will be able to view the result of your analysis. This does not require registration.',
                text_23: 'Calculation results can be exported in popular CAD formats to your computer. You can also get a generated PDF report for each calculation. If you need to add the result of calculations to your resource, it is possible to get a vtu-model. Calculation in this format is conveniently integrated into third-party web resources.',
                text_24: 'You can read detailed documentation describing all the stages of using our service by clicking on the link in the footer of the site.',
                text_25: 'If you have any questions, technical problems or want to leave us wishes, you can write to us at the mail, which you will find in the footer of the site, by clicking on the "contact us" link. You can also use a widget for communication, which is statically fixed in the lower right corner.',

            }
        },
        examples: {
            examples: 'Examples',
            title: 'Calculation of a simple beam.',
            text1: 'Before starting the calculation, you need to import the model. To do this, we will use the upload form.',
            text2: 'In the window that opens, select the required file.',
            text3: 'The modal window that appears allows you to make the initial settings of the model. In it, we can observe the progress of loading the model. It is also possible to rename the model and select the desired system of units.',
            text4: 'We leave the SI unit system, click the "Create" button.',
            text5: 'The server will start generating a preview of the imported model. This usually takes a few seconds. The process does not require waiting and you can open the project right away.',
            text6: 'In the meantime, the preview is ready. You can open a project.',
            text7: 'A workspace opens in front of us. You can learn more about what tools are on the screen in the technical documentation section.',
            text8: 'We have a beam model. Note that the finite element mesh has already been generated.',
            text9: 'For starters, let\'s get acquainted with the characteristics of the model. To do this, go to the subsection of the left menu "Geometry & Mesh". ',
            text10: 'So, the beam consists of one volume, six faces, twelve edges and eight vertices. With a finite element mesh of medium coarseness, the model contains 1269 elements. And you can also make sure that the dimensions of the imported model are what you needed.',
            text11: 'We can also change the roughness of the mesh. If it is reduced, the number of elements in the model will increase. The calculation is more accurate, but it will take more time and require more computing power. If we reduce the coarseness of the grid, we will achieve the opposite effect.',
            text12: 'For our calculation of a mesh of medium coarseness, it will be enough.',
            text13: 'Let\'s start the calculations.',
            text14: 'Let\'s go to the "Materials & BCs" subsection',
            text15: 'Let\'s add some material. To do this, open the "Add Material" field.',
            text16: 'In the field that opens, we see a field for setting the characteristics of the material. In the upper input field, you can rename the material. The "*" symbol in the "Markers" field means that the material will be applied to all volumes of the model. It is also possible to select a material from the material library. This is true for the most common materials. Open the library by clicking on the link "Import from material library".',
            text17: 'For our beam, we\'ll choose steel. You can choose any other material, or flexibly adjust the characteristics of the material you need in the form of settings. Click "Apply".',
            text18: 'Let\'s move on to setting the boundary conditions.',
            text19: 'The drop-down menu lists the available boundary conditions. An unlimited number of GU can be set for one model.',
            text20: 'First of all, let\'s add a movement prohibition to the end of the beam.',
            text21: 'Select "Displacement". Next, you need to select the anchoring entity. At the moment, the service supports three possible types of fixing GU: volume, edge, point. In our calculation, we will use a face for fixing.',
            text22: 'On the model itself, using the mouse, select the faces on which we want to apply the GU. After selection, the face will turn red, and the face id will appear in the "Markers" field.',
            text23: 'Prohibit movement in all three axes. Click Apply.',
            text24: 'Next, let\'s add one more boundary condition. Select "Gravity" in the "Add Boundary Condition" field',
            text25: 'Gravity is applied to the entire volume of the model. Values and volume selection are done automatically. Click "Apply"',
            text26: 'Next, apply force to the other end of the beam. To do this, in the "Add Boundary Condition" field, select the value "Distributed Farce"',
            text27: 'Let\'s turn the beam with the other end using the mouse. Let\'s choose the required edge. Let\'s set the force in the direction of the X axis to 200 Newtons.',
            text28: 'Click apply.',
            text29: 'So, now we have added all the necessary GIs and we can see their list.',
            text30: 'You can add other GUs.',
            text31: 'Finishing the preparation for the calculations, let\'s pay attention to the "Analysis Type" field. Here you can select the required type of analysis. For now, we leave static analysis',
            text32: 'Everything is ready to start the calculation. Click "Start Calculation". Upon completion of the calculation, all the necessary information on the screen will be updated and the calculation results will be displayed.',
            text33: 'The results are ready! Let\'s get acquainted with them.',
            text34: 'In the submenu on the left, you can display the maximum and minimum values. And also see the values at any point. To do this, select "Take Probes", and then click on the point on the model that interests us.',
            text35: 'The "Deformation" field helps visualize plastic deformation.',
            text36: 'The scale associates the values indicated at the top of the scale with a color for more visual analysis. It is possible to limit the maximum and minimum values. The change in the visual component of the calculation results on the model occurs automatically.',
            text37: 'Let\'s decrease the maximum value and notice that the maximum stresses are reached on a larger surface of the model.',
            text38: 'Get the calculation results.',
            text39: 'In the top menu, select "Download Report.pdf"',
            text40: 'You can read this report yourself.',
            text41: 'Also, you can get the opportunity to view the analysis without extraneous interfaces, as well as the ability to share the results on your resource by clicking "Show task in whiteboard"',

        }
    }
}