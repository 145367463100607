export default {
    state: {
        /** @type {{id:string,text:string,type:string,time:Date}[]}*/
        messages: [{
            id: '',
            text: 'Hello!',
            type: 'info', // info|error|warning|success
            time: new Date()
        }],
    },

    getters: {
        messages: s => s.messages,
    },

    mutations: {
        say(state, {text, type = 'info', id = ''}) {
            let time = new Date();
            if (!text) return
            if (id && state.messages.some(
                message => message.id === id
                    && message.text === text
                    && message.type === type
            )) return
            state.messages.unshift({text, type, time, id})
        },
    },
    actions: {
        say(context, {text, type = 'info', id = ''}) {

            context.commit('say', {text, type, id})
            if (type === 'error') {
                context.commit('error_add', text)
            }
        },

        build(context, options) {
            return context.dispatch('kick_ping').then(() =>
                context.getters['net/fetchApi']('build_api', 'POST', options))
        },

        stopBuild(context, mode) {
            return context.dispatch('kick_ping').then(() =>
                context.getters['net/fetchApi']('build_api', 'DELETE', {mode}))
        }
    }
}