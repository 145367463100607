Object.assign(Number.prototype, {
    /**
     * @param {number} pow
     * @return {string}
     */
    display(pow) {
        let v = Math.abs(this);
        if (v < 1e-13) {
            return v.toFixed(pow);
        }

        v = (this + '').split('e', 2);
        let e = Number(v[1] || 0);
        v = Number(v[0]);

        while (e < 0 && Math.floor(v)) {
            v /= 10;
            ++e;
        }

        while (!Number(v.toFixed(pow))) {
            v *= 10;
            --e;
        }

        v = v.toFixed(pow);
        e && (v = [v, e].join('e'));

        return v;
    },

    /**
     * @param {number=} opt_length (default: 2)
     * @return {string}
     */
    pad(opt_length) {
        return (this + '').padStart(opt_length || 2, '0');
    },
});
