export default {
    lang: 'zh',

    header: {
        login: '登录或注册',
        projects: '项目',
        logout: '注销',
        user_settings: '用户设置',
        video: '观看视频教程'
    },
    footer: {
        help: '帮助',
        manual: '手册',
        tutorial: '教程',
        examples: '示例',
        faq: '常见问题解答',
        contact: '联系我们'
    },
    home: {
        title: {
            headline: '下一代云服务',
            description: '在线计算载荷、强度和热弹性',
        },
        button: {
            try_for_free: '免费试用',
        },
        what_types: {
            title: '我们能够进行什么种类的分析?',
            structural_title: '结构',
            structural_description: '计算模型在各种载荷下的强度。',
            thermoelastic_title: '热塑性塑料',
            thermoelastic_description: '计算模型的热塑性。',
            modal_title: '模态',
            modal_description: '计算模型的模态频率和振动形式。',
        },
        simple: {
            simple_touse_and_understand: '易于使用和理解',
            promo_title_text: `在Prove.Design 仿真过程中，您可以即时查看和测试的各种结构设计方案的性能，以实现您的设计目标。`,
            title_1: '3D导入',
            text_1: `将3D模型导入云端，在列表中设置模型的材料特性（您可从库中选择）和受力状态。计算网格将会自动生成。`,
            title_2: '模式选择',
            text_2: '您可以选择静态载荷、热载荷或模态分析。',
            title_3: '结果',
            text_3: `您可以在网页上查看模型的应力和变形图。`,
            how_does_it_works: '它是如何工作的？',
            text_4: 'Prove.Design 为结构工程师提供云服务，可帮助计算设计模型的强度。',
            text_5: '为了使用它，您所需要的只是各大流行CAD软件创建的3D模型。',
            start_production_with: '使用Prove.Design开始您出色的工作',
            click_to_start: '点击下方按钮免费试用',
            free_trial: '免费试用',
        }
    },
    login: {
        title: 'Let\'s start',
        status_text: 'Enter an e-mail and password for a new or existing account',
        submit: 'Next',
    },
    survey: {
        title: 'Tell us about yourself',
        status_text: 'We want to know our customers as best we can in order to further improve our service. Fill out the survey below and get a thousand free Estimated Seconds for your projects!',
        name: 'What is your name?',
        privacy_confirm_1: 'I agree with',
        privacy_confirm_2: 'Personal data processing policy.',
        country: 'Where are you from:',
        company: 'Where do you work or study:',
        is_student: 'I am student or teacher',
        phone: 'Phone number:',
        spam_flag: 'Receive emails from our service. You can unsubscribe at any time.',
        submit: 'Next',
    },
    unconfirmed: {
        welcome: 'Welcome to Prove.Design!',
        logout:'Logout',
        description1: 'You have not confirmed your account. Please check your email ',
        description2: '(and your spam folder) - you should have received an email with a confirmation link.',
        didnt_get:'Didn\'t get the email?',
        resend: 'Resend'
    },
    projects: {
        new_project: 'New Project',
        search: 'Search for projects',
        task: 'task',
        tasks: 'tasks',
        edit: 'Edit project…',
        share: 'Share project…',
        delete: 'Delete project…',
        no_project: 'No projects created',
        loading: 'Loading projects...',
        modal: {
            create: {
                create_task: '创建任务',
                uploaded: '已上传',
                title: '标题',
                source: '资源',
                unit_system: '计量单位',
                calc_unit_system: '计算单位',
                cancel: '取消',
                create: '创建'
            },
            settings: {
                project_settings: '项目设置',
                title: '标题',
                description: '描述',
                public: '发布',
                units: '单位',
                update: '更新'
            },
            share: {
                project_users: '项目用户',
                user_email: '用户邮箱',
                read_only: '只读',
                share: '共享',
                unshare: '取消共享'
            },
            edit_task: {
                edit_task: '编辑任务',
                title: '标题',
                source: '资源',
                cancel: '取消',
                update: '更新'
            }
        }
    },
    tasks: {
        upload: '上传新的CAD模型',
        edit_task: '编辑任务…',
        copy_task: '复制任务…',
        delete_task: '删除任务…',
        generating_preview: '生成预览...'

    },
    profile: {
        header: {
            user_settings: '用户设置',
            logout: '注销'
        },
        captions: {
            'account': '帐户',
            'password': '密码',
            'balance': '余额',
        },
        name: '姓名',
        company: '公司',
        phone: '电话',
        country: '国家',
        preferred_unit_system: '首选的计量单位',
        language: '语言',
        subscribe_to_newsletter: '订阅消息',
        current_password: '当前密码',
        new_password: '新密码',
        update: '更新'
    },
    workbench: {
        modemenu: {
            geometry_and_mesh: '几何结构与网格',
            material_and_bcs: '材料与边界条件',
            view_result: '查看结果',
            building_geometry: '几何结构建立...',
            rebuild_geometry: '几何结构重建',
            build_geometry: '建立',
            meshing: '网格化...',
            rebuild_mesh: '重建网格',
            build_mesh: '构建网格',
            calculate: '计算...',
            restart_calculation: '重新计算',
            start_calculation: '开始计算',
            set_material_and_bc: '设置材料、边界条件和计算',
            zero_balance: '您的余额为零，请为账户充值',
            analysis_type: {
                Static: '静态',
                Modal: '模态',
                Buckling: '屈曲'
            }
        },
        statistics: {
            task_statistics: '任务统计',
            volume: '体积',
            surface: '曲面',
            curve: '曲线',
            vertex: '顶点',
            coarseness: '粗糙度',
            node: '节点',
            element: '元素',
            bounding_box: "尺寸",
            total_volume: "总体积",
            total_area: '总面积',
            size_x: '尺寸 X',
            size_y: '尺寸 Y',
            size_z: '尺寸 Z'
        },
        build_button: {
            coarseness: '粗糙度',
            set_result_name: '设置结果名称',
            analysis_type: '分析类型',
            elasticity: '弹性',
            plastisity: '塑性',
            heattrans: '热传导',
            preload_model: '预加载模型',
            number_of_eigen_frequencies: '特征频率数',
            number_of_buckling_modes: '屈曲模式数'
        },
        tree: {
            material: {
                add_material: '添加材料',
                materials: '材料',
                markers: '标记',
                import_from_material_library: '从材料库导入',
            },
            bcs: {
                add_boundary_condition: '添加边界条件',
                fixed: '固定',
                displacement: '位移',
                pressure: '压力',
                gravity: '重力',
                angular_velocity: '角速度',
                distributed_force: '分布式力',
                temperature: '温度',
                convection: '对流',
                boundary_conditions: '边界条件'
            },
            select: {
                surface: '曲面',
                node: '节点',
                volume: '体积',
                markers: '标记',
                apply: '应用',
                si: 'SI',
                cgs: 'CGS',
                mmns: 'MMNS',
                fps: 'FPS',
                ips: 'IPS'
            },
            result: {
                deformation: '变形',
                values: '值',
                drop: '放置',
                take: '获取',
                probes: '取样',
                hide: '隐藏',
                show: '显示',
                safety_factor: '安全系数',
                little_stress: '微小应力',
                significant_stress: '显著应力！',
                critical_stress: '临界应力！'
            },
        },
        topmenu: {
            mesh: '网格',
            inside: '里面',
            scale: '比例',
            axes: '轴',
            legend: '图例',
            console: '控制台',
            internal_curves: '内部边缘'
        },
        lut: {
            Displacement: '位移',
            Magnitude: '幅度',
            Mises: '米塞斯',
            'Principal 1': '第一主应力',
            'Principal 2': '第二主应力',
            'Principal 3': '第三主应力',
            'Yield strength': '屈服强度',
            'Ultimate strength': '极限强度',
            'Mass acceleration': 'Mass acceleration',
            'Thermal Stress': 'Thermal Stress',
            'Thermal Strain': 'Thermal Strain',
            'Heat Flux': 'Heat Flux',
            'Angular Velocity': 'Angular Velocity',
            'Temperature': 'Temperature',
            'Yield': 'Yield strength',
            'Ultimate': 'Ultimate strength',
            'Elastic Strain': 'Elastic Strain',
            'Plastic Strain': 'Plastic Strain',
            'Safety Factor 1-Principal': 'Safety Factor 1-Principal',
            'Safety Factor Mises': 'Safety Factor Mises',
            'Local Coordinate System Vector X': '局部坐标系向量X',
            'Local Coordinate System Vector Y': '局部坐标系向量Y',
            'Local Coordinate System Vector Z': '局部坐标系向量Z',
            'Principal stress vector 1': '第一主应力向量',
            'Principal stress vector 2': '第二主应力向量',
            'Principal stress vector 3': '第三主应力向量',
            Strain: '应变',
            'Strength Properties': '强度属性',
            Stress: '应力',
            X: 'X',
            Y: 'Y',
            Z: 'Z',
            XX: 'XX',
            YY: 'YY',
            ZZ: 'ZZ',
            XY: 'XY',
            YZ: 'YZ',
            XZ: 'XZ',
            value: 'Value'            
        }
    },
    help: {
        to_contact_us: '联系我们',
        write_to: '写给',
        or_click_button: '或者点击右下角的按钮',
        manual: {
            user_manual: "用户手册",
            project_settings: '项目设置',
            model_settings: '模型设置',
            what_is_on_the_screen: '屏幕上有什么',
            title: {
                title_1: '开关面板',
                title_2: '模型定位',
                title_3: '日志窗口',
                title_4: '模型信息',
                title_5: '设置有限元网格',
                title_6: '结果输出比例',
                title_7: '添加材料',
                title_8: '添加边界条件',
                title_9: '开始计算',
                title_10: '计算后',
                title_11: '如何更改项目',
                title_12: '如何共享项目',
                title_13: '如何删除项目',
                title_14: '如何将模型添加到项目',
                title_15: '如何重命名模型',
                title_16: '如何复制模型',
                title_17: '如何将模型从一个项目移动到另一个项目',
                title_18: '如何删除模型',

            },
            text: {
                text_1: '屏幕顶部的开关栏可以设置用来只显示你需要的信息。你可以禁用你不使用的工具。',
                text_2: '开关用来进行以下的操作',
                text_3: '“网格”- 启用或禁用模型上的有限元网格的显示。。',
                text_4: '“內部”- 启用或禁用模型纹理的透明度。',
                text_5: '“比例尺”- 启用或禁用外部模型尺寸的显示。',
                text_6: `“轴”- 启用或禁用模型在空间的位置控件。`,
                text_7: '“图例”- 启用或禁用色标和计算结果的数值。',
                text_8: '“控制台”- 启用或禁用对话框的透明度。。',
                text_9: '“结果”——导入包含计算结果的文件，生成和获取 PDF 格式的详细计算结果。',
                text_10: '您可以使用鼠标在空间中重新定位模型。 为此，请单击模型或附近的可用空间，然后在不释放鼠标按钮的情况下向所需方向拖动。',
                text_11: '右上角的控件用来显示模型相对于主轴的位置。',
                text_12: `该控件件还可以用来更改模型在空间中的方向。通过单击箭头，您可以围绕主轴旋转模型。旋转以 36 度为增量进行。要添加模型的正交位置，您可以点击我们需要的控件的面。要增加或减少模型的显示，您需要使用滚轮。如有必要，可以通过单击顶部菜单中的“轴”按钮来禁用模型位置控件。`,
                text_13: '日志对话框存储用户与模型交互的历史记录。来自服务器的响应、结果和模型的状态也将显示在对话框中。为方便起见，对话框的背景是透明的，可以使用顶部栏中的“控制台”开关使其更明显地显示。',
                text_14: '有关加载模型的信息可以在菜单选项卡几何结构和网格中找到',
                text_15: '您可以在几何和网格菜单选项卡中配置有限元网格。您可以使用滑块更改网格的粗糙度。如果单击“重新计算网格”按钮，服务器将重新计算有限元网格并将结果显示在模型上。',
                text_16: '通过改变网格的粗糙度，可以提高计算结果的精度，或者在对计算要求不是很精确的情况下可以节省计算时间。',
                text_17: '在结果输出模式下，值标尺将颜色与结果的数值相关联。',
                text_18: '比例顶部的字段允许您切换输出结果的实体及其方向。自动更改模型上计算结果的显示。',
                text_19: '上下限值的限制可以独立设置。为此，请在上限值字段中输入所需的最大限制。对下方输入字段中的最小值也这样做。在模型上显示的结果自动生成。',
                text_20: '在“材料和边界条件”选项卡中，选择“添加材料”项。',
                text_21: '您可以通过使用所需材料的特性值填写表格来创建任何材料。也可以通过单击“从材料库导入”按钮打开它，从库中选择材料',
                text_22: '要删除材料，请单击标注右侧的垃圾桶图标。',
                text_23: '在“材料和边界条件”选项卡中，选择“添加边界条件”项。',
                text_24: '从建议的列表中选择所需的边界条件。',
                text_25: `选择边界条件应用的实体（面、体积、点）。选择边界条件应用的所有必需的实体。还需要指定边界条件的值或移动的值。进行必要的设置后，单击“应用”按钮。可以添加无限数量的边界条件。`,
                text_26: '在开始计算之前，您必须在位于“材料和边界条件”选项卡底部的表格中选择分析模式。',
                text_27: `开始计算的必要条件是向模型的所有体积添加材料，以及添加边界条件。请注意，为了计算施加的力或计算模态分析，开始计算的先决条件是约束模型的定义。如果满足所有必要条件，“开始计算”按钮将被激活。通过按下它，服务器将开始计算。在对话框中，计算的步骤和结果。计算完成后，会自动输出结果。`,
                text_28: '计算结果可以在“查看结果”部分进行管理。',
                text_29: '使用结果输出选项卡上部的选项可以浏览计算结果。',
                text_30: '您可以在下一个菜单中查看输出数据的最大值和最小值。也可以在模型中的任意点取值。为此，您需要按住“取样”按钮并在模型上的所需位置放置一个点。',
                text_31: '您可以使用以下工具可视化模型位移和变形：',
                text_32: '计算结束后，您可以通过点击屏幕顶部的“结果”按钮获得报告。',
                text_33: '除了导入计算结果的功能外，还可以获得PDF格式的报告。',
                text_34: '"在白板中显示任务"将在新选项卡中打开没有UI元素的模型视图。',
                text_35: '"下载 结果.vtu" 在您的网站上导入一个方便使用计算结果的文件.',
                text_36: '如有必要，更改或调整材料性质或 HA 覆盖设置。调整设置后，必须单击“重新计算结果”按钮。',
                text_37: '在项目页面上，您可以创建新项目，也可以对现有项目进行更改。要创建新项目，请单击左上角的新建按钮。',
                text_38: '一个项目是根据一个或另一个原则联合起来的一个或多个模型。项目可以轻松地对模型进行排序。要重命名项目，请单击项目名称右侧的三个点。',
                text_39: '调用的环境菜单可以重命名项目、更改访问权限和删除项目。“编辑项目”调出模态窗口。',
                text_40: '使用它，您可以重命名项目、添加描述、将项目设为 /静止 以及更改项目的优先计量单位。完成所有必要的更改后，单击“更新”按钮即可将所做的更改应用到项目中。',
                text_41: '要向其他人扩展对项目的访问权限，您需要单击“共享项目”。',
                text_42: '在打开的窗口中，您必须在输入字段中指定将获得项目访问权限的用户。“只读”标志表示获得访问权限的用户是否有权更改和编辑项目。如果启用该标志，则具有访问权限的用户将只能查看结果。模态框右下角的绿色按钮会向指定用户发送邀请链接。',
                text_43: '要删除项目，请单击项目名称右侧的三个点。',
                text_44: '“删除项目”将弹出一个模态窗口，要求您删除该项目。 如果确认此操作，项目及其包含的所有内容都将被删除。',
                text_45: '您可以使用上传卡将模型添加到项目中。为此，您必须先将模型下载到您的计算机上。单击下载卡并选择所需的文件。',
                text_46: '请注意，模型必须与 Prove.design 使用的扩展相匹配。 或者，您可以使用拖放来加载模型并将模型文件拖放到项目区域中。',
                text_47: '其中，您可以指定名称，以及为模型选择单位。默认情况下，计量单位系统将与项目的单位相匹配。但如有必要，可以为每个模型单独设置测量单位。模型的创建完成将按下“创建”按钮。',
                text_48: '添加模型后，稍后将生成模型的“预览”，之后可以在计算复合体中打开模型。',
                text_49: '在卡片的右上角，单击省略号按钮。 在出现的上下文菜单中，选择“编辑模型”字段。 在出现的模态窗口中重命名模型，然后单击“应用”。',
                text_50: '在打开的窗口中，您必须在输入字段中指定将获得项目访问权限的用户。 “只读”标志指示获得访问权限的用户是否可以更改和编辑项目。 如果启用该标志，则具有访问权限的用户将只能查看结果。 模态框右下角的绿色按钮会向指定用户发送邀请链接。',
                text_51: '在卡片的右上角，单击省略号按钮。 在出现的环境文菜单中，选择“复制模型”字段。 模型的副本将出现在带有索引 (2) 的项目中。',
                text_52: '如果您需要将模型从一个项目移动到另一个项目，请使用拖放操作。为此，用光标点住所需的模型，在不释放鼠标按钮的情况下，将模型传输到所需的项目，然后释放鼠标按钮。该模型将从一个项目转移到另一个项目。在传输时，所有在计算的模型将被保存。',
                text_53: '在卡片的右上角，单击省略号按钮。在出现的环境菜单中，选择删除模型字段。在出现的对话框中，您必须确认该操作。',

            }
        },
        tutorial: {
            tutorial: '教程'
        },
        faq: {
            faq: '常见问题',
            title: {
                title_1: '什么是',
                title_2: '可以进行哪些计算？',
                title_3: '哪些CAD格式',
                title_4: '支持',
                title_5: '采用什么计算引擎',
                title_6: '为了谁',
                title_7: '合适',
                title_8: '关于结构分析我需要了解什么？',
                title_9: '我如何开始使用？',
                title_10: '我可以查看计算示例吗？',
                title_11: '如何与同事或客户共享计算结果？',
                title_12: '我怎样才能得到计算结果？',
                title_13: '我在哪里可以阅读用户手册？',
                title_14: '如何联系我们？',
            },
            text: {
                text_1: '它是基于云的 CAE 服务。 有了它，您可以对您的工程解决方案进行计算力学分析。',
                text_2: '允许计算静态应力，以及模态和热平衡分析。',
                text_3: '哪些优势',
                text_4: '提供给用户',
                text_5: '不需要在您的设备上安装软件。',
                text_6: '您不需要高性能设备。 所有计算都在功能强大的服务器上进行。为了方便使用，您只需要稳定的互联网连接。',
                text_7: '您可以从不同的设备执行计算和查看计算结果。您所要做的就是授予所需项目的权限。',
                text_8: '方便直观的界面适用于小屏幕移动设备。',
                text_9: '该服务支持最常见的 CAD 格式，例如 .stp, .sat, .stl, .igs.',
                text_10: 'Prove.design 使用的计算核心是',
                text_11: 'CAE Fidesys 软件包。',
                text_12: '我们的服务适合那些需要通过简单计算来分析模型特性的用户。 例如，如果您需要计算为 3D 打印设计的零件的静态应力。还有，',
                text_13: '适用于撰写报告和文章、进行教育工作、准备演示和培训材料等。',
                text_14: '我们创建了一个简化的建模过程，以最大限度地减少结构分析所需的知识。简化的工作流程包括基于项目设置的智能自动化，以自动执行日常任务。该过程由简单的步骤组成',
                text_15: '创建项目（加载模型、网格并根据项目默认值设置材料属性）',
                text_16: '设置边界条件（载荷和约束）',
                text_17: '执行分析，以及',
                text_18: '查看结果。',
                text_19: '有关更多信息，请参阅网站上提供的技术文档、手册和示例。',
                text_20: '如果要开始，只需注册。 即使您还没有任何 CAD 模型，您也可以使用内置模型库探索我们的服务。',
                text_21: '您可以在技术文档部分找到计算示例，链接位于网站的页脚中。本节还包含教学视频。 我们已尝试详细描述执行分析任务的细节，以便您可以快速地进入角色。',
                text_22: '在项目设置中，您可以找到一个用于向其他用户授予访问权限的表单。为此，您必须指定要授予访问权限的用户的电子邮件地址。收到电子邮件并点击链接的任何人都可以查看您的分析结果。 这不需要注册。',
                text_23: '计算结果可以以流行的计算机辅助设计模型格式导出到您的计算机。 您还可以为每个计算获得生成的PDF报告。 如果您需要将计算结果添加到资源中，则可以获得vtu模型。 这种格式的计算可以方便地集成到第三方web资源中。',
                text_24: '单击网站页脚中的链接，您可以找到描述使用我们服务的所有步骤的详细文档。',
                text_25: '如果您有任何疑问、技术问题，或想给我们留下反馈，您可以通过单击“联系我们”链接向网站页脚中的电子邮件地址发送电子邮件。您还可以使用固定在右下角的聊天控件。。',

            }
        },
        examples: {
            examples: '例子',
            title: '简单梁的计算。',
            text1:'在开始计算之前，您需要导入模型。为此，我们将使用上传表单。',
            text2:'在打开的窗口中，选择所需的文件。',
            text3:'出现的模态窗口允许您对模型进行初始设置。 在其中，我们可以观察加载模型的进度。 也可以重命名模型并选择所需的单位制。',
            text4:'离开SI单位制，单击"创建"按钮。',
            text5:'服务器将开始生成导入模型的预览。 这通常需要几秒钟。该过程不需要等待，您可以立即打开项目。',
            text6:'在此期间预览准备就绪。 你可以打开项目。',
            text7:'工区已打开。 您可以在技术文档部分中详细了解屏幕上的工具。',
            text8:'我们有一个梁模型。 请注意，有限元网格已经生成。',
            text9:'首先，让我们熟悉模型的特性。 为此，请转到左侧菜单"几何结构和网格"部分。 ',
            text10:'所以，梁由一个体积，六个面，十二条边和八个顶点组成。 使用中等粗糙度的有限元网格，该模型包含1269个单元。 您还可以确保导入模型的尺寸是您所需要的。',
            text11:'我们也可以改变网格的粗糙度。 如果网格加密，模型中的元素数量将增加。 计算更准确，但会花费更多的时间，需要更多的计算能力。 如果我们增加网格的粗糙度，我们将达到相反的效果。',
            text12:'对于我们计算中等粗糙度的网格，这就足够了。',
            text13:'让我们开始计算。',
            text14:'让我们转到"材料和边界条件"部分',
            text15:'让我们添加材料。为此，请打开"添加材料"字段。',
            text16:'在打开的字段中，我们看到一个用于设置材料特性的字段。 在上方的输入字段中，您可以重命名材料。 “标记”字段中的符号“*”表示该材料将应用于模型的所有体积。 您也可以从材料库中选择一种材料。 这适用于最常见的材料。 单击“从材料库导入”链接打开库。',
            text17:'对于我们的梁，我们选择钢。您可以选择任何其他材料，或者以设置的形式灵活调整您需要的材料的性质。点击"应用"。',
            text18:'让我们继续设置边界条件。',
            text19:'下拉菜单列出了可用的边界条件。一个模型可以设置无限数量的边界条件.',
            text20:'首先，让我们在射线的末端添加一个禁止运动的条件。',
            text21:'选择"位移"。接下来，您需要选择锚定实体。目前，该服务支持三种可能的固定类型：体积，边缘，点。 在我们的计算中，我们将使用面进行固定。',
            text22:'在模型本身上，使用鼠标，选择要应用边界条件的面。选择后，脸将变为红色，面的id将出现在"标记"字段中。',
            text23:'禁止在所有三个轴上移动。单击应用。',
            text24:'接下来，让我们再添加一个边界条件。在"添加边界条件"字段中选择"重力"',
            text25:'重力应用于模型的整个体积。值和体积的选择是自动完成的。点击"应用"',
            text26:'接下来，对梁的另一端施加力。为此，在"添加边界条件"字段中，选择值"分布式力"',
            text27:'让我们用鼠标转动梁的另一端。让我们选择所需的边。让我们将x轴方向的力设置为200N。',
            text28:'点击应用。',
            text29:'所以，现在我们已经添加了所有必要的边界条件，我们可以看到它们的列表。',
            text30:'你可以添加其他边界条件。',
            text31:'完成计算的准备工作，让我们注意"分析类型"字段。 在这里，您可以选择所需的分析类型。 现在，我们暂时离开静态分析',
            text32:'一切都准备好开始计算。 点击"开始计算"。 计算完成后，屏幕上的所有必要信息会更新，计算结果会显示出来。',
            text33:'结果计算出来了！ 让我们看看吧。',
            text34:'在左侧的子菜单中，可以显示最大值和最小值。并且还可以看到任何点上的值。为此，请选择"取样探头"，然后单击我们感兴趣的模型上的点。',
            text35:'"变形"字段有助于可视化塑性变形。',
            text36:'标尺将刻度顶部指示的值与颜色相关联，以便进行更直观的分析。 可以限制最大值和最小值。模型上计算结果的可视化组件的变化自动生成。',
            text37:'减小最大值，并注意到最大应力在模型的较大表面上达到。',
            text38:'获得计算结果。',
            text39:'在顶部菜单中，选择"下载 报告.pdf"',
            text40:'你可以自己阅读这份报告。',
            text41:'您还可以在没有无关界面的情况下查看分析结果，以及可以通过单击“在白板上显示任务”来共享资源结果。',
        }
    }
}