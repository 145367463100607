export default {
    lang: 'ru',

    header: {
        login: 'Войти',
        projects: 'Проекты',
        logout: 'Выйти',
        user_settings: 'Настройки',
        video: 'Смотреть видеоурок'
    },
    footer: {
        help: 'Помощь',
        manual: 'Мануал',
        tutorial: 'Обучение',
        examples: 'Примеры',
        faq: 'FAQ',
        contact: 'Контакты'
    },
    home: {
        title: {
            headline: 'Облачный CAE сервис',
            description: 'Расчет нагрузки, предела прочности и теплоупругости онлайн',
        },
        button: {
            try_for_free: 'Попробовать бесплатно',
        },
        what_types: {
            title: 'Какие виды анализа мы умеем производить?',
            structural_title: 'Структурный',
            structural_description: 'Рассчитайте прочность модели при различных нагрузках.',
            thermoelastic_title: 'Термоупругий',
            thermoelastic_description: 'Рассчитайте термоупругость модели.',
            modal_title: 'Модальный',
            modal_description: 'Рассчитайте модальные частоты и формы колебаний модели.',
        },
        simple: {
            simple_touse_and_understand: 'Простой в использовании и понимании',
            promo_title_text: `Prove.design позволяет вам
                    мгновенно просматривать и тестировать альтернативные варианты характеристик конструкции для достижения ваших целей проектирования`,
            title_1: '3D Импорт',
            text_1: `3D-модель импортируется в сервис, после чего вы  указываете материал, из которого изготовлена модель (вы можете выбрать из библиотеки), какие силы действуют на нее. Расчетная сетка будет построена автоматически.`,
            title_2: 'Выбор режима',
            text_2: 'Вы можете выбрать статическую нагрузку, термостатику или модальный анализ.',
            title_3: 'Результат',
            text_3: `Вы сможете изучить картину напряжения и деформации модели в web-интервейсе`,
            how_does_it_works: 'Как это работает?',
            text_4: 'Prove.Design это облачный сервис для конструкторов, который помогает рассчитать прочность спроектированной модели.',
            text_5: 'Все, что вам нужно для его использования, - это 3D-модель, созданная в одном из популярных пакетов САПР',
            start_production_with: 'Начните создавать свою лучшую работу с Prove.design',
            click_to_start: 'Нажмите ниже, чтобы начать бесплатно',
            free_trial: 'Попробовать бесплатно',
        }
    },
    login: {
        title: 'Давайте начнем',
        status_text: 'Введите e-mail и пароль для создания нового или захода в уже существующий акаунт',
        submit: 'Далее',
    },
    survey: {
        title: 'Расскажите нам о себе',
        status_text: 'Мы хотим узнать наших клиентов как можно лучше. Заполните анкету и получите десять тысяч расчетных секунд для своих проектов!',
        name: 'Как вас зовут?',
        privacy_confirm_1: 'Я согласен с',
        privacy_confirm_2: ' политикой обработки персональных данных.',
        country: 'Откуда вы?',
        company: 'Где вы учитесь или работаете?',
        is_student: 'Отметьте, если вы учитесь или преподате',
        phone: 'Телефон',
        spam_flag: 'Согласны ли вы получать рассылку с новостями о нашей работе? Вы можете отписаться в любой момент.',
        submit: 'Далее',
    },
    unconfirmed: {
        welcome: 'Добро пожаловать на Prove.Design!',
        logout:'Выйти',
        description1: 'Ваша учетная запись не подтверждена. Пожалуйста, проверьте свой почтовый ящик ',
        description2: '(и папку со спамом) - вы должны получить электронное письмо со ссылкой для подтверждения.',
        didnt_get:'Письмо не дошло?',
        resend: 'Перевыслать'
    },
    projects: {
        new_project: 'Новый проект',
        search: 'Поиск проектов',
        task: 'задачи',
        tasks: 'задач',
        edit: 'Редактировать проект...',
        share: 'Поделиться проектом...',
        delete: 'Удалить проект...',
        no_project: 'Проекты не созданы',
        loading: 'Загрузка проектов...',
        modal: {
            create: {
                create_task: 'Создать задачу',
                uploaded: 'Загружено',
                title: 'Заголовок',
                source: 'Источник',
                unit_system: 'Система измерения',
                calc_unit_system: 'Система единиц вычисления',
                cancel: 'Отмена',
                create: 'Создать'
            },
            settings: {
                project_settings: 'Настройки проекта',
                title: 'Заголовок',
                description: 'Описание',
                public: 'Общедоступный',
                units: 'Единицы измерения',
                update: 'Обновить'
            },
            share: {
                project_users: 'Пользователи проекта',
                user_email: 'Е-mail пользователя',
                read_only: 'Только для чтения',
                share: 'Поделиться',
                unshare: 'Запретить доступ'
            },
            edit_task: {
                edit_task: 'Редактировать задачу',
                title: 'Заголовок',
                source: 'Источник',
                cancel: 'Отменить',
                update: 'Обновить'
            }
        }
    },
    tasks: {
        upload: 'Загрузить новую модель САПР',
        edit_task: 'Редактировать задачу...',
        copy_task: 'Копировать задачу..',
        delete_task: 'Удалить задачу...',
        generating_preview: 'Загружаю модель...'

    },
    profile: {
        header: {
            user_settings: 'Настройки пользователя',
            logout: 'Выйти из системы'
        },
        captions: {
            'account': 'Настройки пользователя',
            'password': 'Пароль',
            'balance': 'Баланс',
        },
        name: 'Имя',
        company: 'Компания',
        phone: 'Телефон',
        country: 'Страна',
        preferred_unit_system: 'Предпочитаемая система единиц измерения',
        language: 'Язык',
        subscribe_to_newsletter: 'Подписка на рассылку новостей',
        current_password: 'Текущий пароль',
        new_password: 'Новый пароль',
        update: 'Обновить'
    },
    workbench: {
        modemenu: {
            geometry_and_mesh: 'Геометрия и сетка',
            material_and_bcs: 'Материалы и ГУ',
            view_result: 'Результаты',
            building_geometry: 'Построение геометрии...',
            rebuild_geometry: 'Востановление геометрии',
            build_geometry: 'Построение',
            meshing: 'Построение сетки...',
            rebuild_mesh: 'Перестроить сетку',
            build_mesh: 'Построить сетку',
            calculate: 'Вычисление...',
            restart_calculation: 'Произвести расчет',
            start_calculation: 'Начать расчет',
            set_material_and_bc: 'Установите материал, граничные условия и настройки расчета',
            zero_balance: 'Ваш баланс равен нулю. Пожалуйста, пополните свой аккаунт',
            analysis_type: {
                Static: 'Статический',
                Modal: 'Модальный',
                Buckling: 'Потеря устойчивости'
            }
        },
        statistics: {
            task_statistics: 'Информация о модели',
            volume: 'Тел',
            surface: 'Граней',
            curve: 'Ребер',
            vertex: 'Вершин',
            coarseness: 'Грубость сетки',
            node: 'Узлов',
            element: 'Элементов',
            bounding_box: "Размер",
            total_volume: "Общий объем",
            total_area: 'Общая площадь поверхности',
            size_x: 'Размер по X',
            size_y: 'Размер по Y',
            size_z: 'Размер по Z'
        },
        build_button: {
            coarseness: 'Грубость сетки',
            set_result_name: 'Имя результата',
            analysis_type: 'Тип анализа',
            elasticity: 'Эластичность',
            plastisity: 'Пластичность',
            heattrans: 'Теплопередача',
            preload_model: 'Преднагруженная модель',
            number_of_eigen_frequencies: 'Собственных частот',
            number_of_buckling_modes: 'Форм потери устройчивости'
        },
        tree: {
            material: {
                add_material: 'Добавить материал',
                materials: 'Материалы',
                markers: 'Маркеры',
                import_from_material_library: 'Добавить из библиотеки материалов',
            },
            bcs: {
                add_boundary_condition: 'Добавить граничное условие',
                fixed: 'Фиксация',
                displacement: 'Перемещение',
                pressure: 'Давление',
                gravity: 'Гравитация',
                angular_velocity: 'Угловая скорость',
                distributed_force: 'Распределенная сила',
                temperature: 'Температура',
                convection: 'Конвекция',
                heat_flux: 'Тепловой поток',
                boundary_conditions: 'Граничные условия'
            },
            select: {
                surface: 'Грань',
                node: 'Точка',
                volume: 'Объем',
                markers: 'Маркеры',
                apply: 'Применить',
                si: 'Си',
                cgs: 'СГС',
                mmns: 'ммНс',
                fps: 'FPS',
                ips: 'IPS'
            },
            result: {
                deformation: 'Деформация',
                values: 'Значения',
                drop: 'Поставить',
                take: 'Получить',
                probes: 'Значение',
                hide: 'Закрыть',
                show: 'Показать',
                safety_factor: 'Запас прочности',
                little_stress: 'Небольшой стресс',
                significant_stress: 'Значительный стресс!',
                critical_stress: 'Критический стресс!'
            },

        },
        topmenu: {
            mesh: 'Сетка',
            inside: 'Изнутри',
            scale: 'Масштаб',
            axes: 'Оси',
            legend: 'Легенда',
            console: 'Консоль',
            internal_curves: 'Грани'
        },
        lut: {
            Displacement: 'Перемещение',
            Stress: 'Напряжения',
            Strain: 'Деформации',
            Magnitude: 'Модуль',
            Mises: 'Мизес',
            'Principal 1': 'Главный 1',
            'Principal 2': 'Главный 2',
            'Principal 3': 'Главный 3',
            'Yield strength': 'Предел текучести',
            'Ultimate strength': 'Предел прочности',
            'Mass acceleration': 'Массовые силы',
            'Thermal Stress': 'Температурные напряжения',
            'Thermal Strain': 'Температурные деформации',
            'Heat Flux': 'Поток тепла',
            'Angular Velocity': 'Угловая скорость',
            'Temperature': 'Температура',
            'Yield': 'Предел текучести',
            'Ultimate': 'Предел прочности',
            'Elastic Strain': 'Эластические деформации',
            'Plastic Strain': 'Пластические деформации',
            'Safety Factor 1-Principal': 'Предел прочности по главным напряжениям',
            'Safety Factor Mises': 'Предел прочности по Мизесу',
            'Local Coordinate System Vector X': 'Вектор локальной системы координат X',
            'Local Coordinate System Vector Y': 'Вектор локальной системы координат Y',
            'Local Coordinate System Vector Z': 'Вектор локальной системы координат Z',
            'Principal stress vector 1': 'Основной вектор напряжения 1',
            'Principal stress vector 2': 'Основной вектор напряжения 2',
            'Principal stress vector 3': 'Основной вектор напряжения 3',
            'Strength Properties': 'Прочностные свойства',
            X: 'X',
            Y: 'Y',
            Z: 'Z',
            XX: 'XX',
            YY: 'YY',
            ZZ: 'ZZ',
            XY: 'XY',
            YZ: 'YZ',
            XZ: 'XZ',
            value: 'Значение'
        }
    },
    help: {
        to_contact_us: 'Чтобы связаться с нами',
        write_to: 'напишите в',
        or_click_button: 'Или нажмите на кнопку в правом нижнем углу',
        manual: {
            user_manual: "Руководство пользователя",
            project_settings: 'Настройки проекта',
            model_settings: 'Настройки модели',
            what_is_on_the_screen: 'Что находится на экране',
            title: {
                title_1: 'Панель переключателей',
                title_2: 'Ориентация  модели',
                title_3: 'Окно логов',
                title_4: 'Информация о модели',
                title_5: 'Настройка сетки конечных элементов',
                title_6: 'Масштаб вывода результата',
                title_7: 'Добавление материала',
                title_8: 'Добавление граничных условий',
                title_9: 'Начало расчета',
                title_10: 'После расчета',
                title_11: 'Как изменить проект',
                title_12: 'Как поделиться проектом',
                title_13: 'Как удалить проект',
                title_14: 'Как добавить модель в проект',
                title_15: 'Как переименовать модель',
                title_16: 'Как скопировать модель',
                title_17: 'Как переместить модель из одного проекта в другой',
                title_18: 'Как удалить модель',

            },
            text: {
                text_1: 'Панель переключателей в верхней части экрана позволяет выводить на экран только необходимую информацию. Пользователь может отключить те инструменты, которые не использует.',
                text_2: 'Переключатели отвечают за следующие действия',
                text_3: '"Mesh" - Включает и отключает отображение сетки конечных элементов на модели.',
                text_4: '"Inside" - Включает и отключает прозрачность текстур модели.',
                text_5: '"Scale bar" - Включает и отключает отображение внешних габаритов модели.',
                text_6: `"Axes" - Включает и отключает виджет положения модели в пространстве.`,
                text_7: '"Legend" - Включает и отключает шкалу цветов и числовых значений результатов вычислений.',
                text_8: '"Console" - Включает и отключает прозрачность подложки диалогового окна.',
                text_9: '"Result" - импортируйте файлы с результатами расчетов, а также генерируйте и получайте подробные результаты расчетов в формате PDF.',
                text_10: 'Вы можете использовать мышь для изменения положения модели в пространстве. Для этого нажмите на модели, или свободном пространстве рядом и, не отпуская клавишу мыши, потяните в нужном направлении.',
                text_11: 'Виджет в правом верхнем углу покажет положение модели относительно главных осей.',
                text_12: `Виджет также может изменять ориентацию модели в пространстве. Нажимая на стрелки, вы можете поворачивать модель относительно основных осей. Вращение происходит с шагом в 36 градусов.
                             Чтобы добиться ортогонального положения модели, вы можете нажать на нужную нам грань виджета.
                             Чтобы увеличить или уменьшить отображение модели, необходимо использовать колесико.
                             При необходимости виджет положения модели можно отключить, нажав кнопку "Оси" в верхнем меню.`,
                text_13: 'Диалоговое окно хранит историю взаимодействия пользователя с моделью. Ответ с сервера, результаты и состояние модели также будут отображаться в диалоговом окне. Для удобства, фон диалогового окна прозрачный, однако его можно сделать явным, воспользовавшись переключателем «Console» в верхней панели.',
                text_14: 'Информация о загруженной модели можно найти во вкладке меню Геометрия & Сетка',
                text_15: 'Настроить сетку конечных элементов можно во вкладке меню Geometry & Mesh. С помощью ползунка можно изменить зернистость сетки. Если нажать на кнопку «Пересчитать сетку» сервер произведет перерасчет сетки конечных элементов и отобразит результат на модели.',
                text_16: 'Изменяя зернистость сетки можно повысить точность результатов вычислений или сэкономить вычислительное время, если расчет требуется не очень точный.',
                text_17: 'В режиме вывода результатов шкала значений связывает цвет с числовым значением полученных результатов.',
                text_18: 'Посмотреть максимальное и минимальное значение выводимых данных можно в следующем меню. Также возможно взять значение в произвольной точки модели. Для этого необходимо зажать кнопку «Взять пробу» и поставить точку на модели в необходимом месте.',
                text_19: 'Максимальное и минимальное предельные значения могут быть ограничены независимо. Для этого введите максимальный требуемый предел в поле верхнего значения. То же самое следует проделать с минимальным значением в нижнем поле ввода. Отображение результатов на модели происходит автоматически.',
                text_20: 'Во вкладке «Материалы и граничные условия» выбрать позицию «добавить материал».',
                text_21: 'Вы можете создать любой материал, заполнив форму значениями характеристики требуемого материала. Также можно выбрать материал из библиотеки, открыв его, нажав на кнопку "Импортировать из библиотеки материалов".',
                text_22: 'Чтобы удалить материал, нажмите на значок корзины справа от заголовка.',
                text_23: 'На вкладке "Материалы и граничные условия" выберите пункт "Добавить граничные условия".',
                text_24: 'Выбрать необходимое ГУ из списка предложенных.',
                text_25: `Выбрать сущность приложения ГУ (грань, объем, точка). Выбрать все необходимые сущности приложения ГУ. Так же необходимо указать значение величины или перемещения ГУ. После выполнения необходимых настроек нажать кнопку «Применить».
                            Имеется возможность добавлять неограниченное количество ГУ.`,
                text_26: 'Перед началом расчета, необходимо выбрать режим анализа в форме, находящейся в нижней части вкладки «Материалы и ГУ».',
                text_27: `Необходимым условием для начала расчета является добавление материала на все объемы модели, а так же добавление граничных условий. Обратите внимание, для расчета приложенной силы или для расчета модального анализа необходимым условием для начала расчета является задание модели закрепления. 
                            Если все необходимые условия выполнены, кнопка «Начать расчет» будет активной. Нажав ее, сервер начнет выполнение расчетов.
                            В диалоговом окне шаги и результаты расчетов. После завершения расчета, вывод результатов произойдет автоматически.`,
                text_28: 'Результатами расчета можно управлять в разделе "Просмотр результата".',
                text_29: 'Навигация по результатам вычислений осуществляется с помощью переключателя в верхней части вкладки.',
                text_30: 'Вы можете просмотреть максимальное и минимальное значение выходных данных в следующем меню. Также возможно принять значение в произвольной точке модели. Для этого вам нужно, удерживая нажатой кнопку "Взять пробники", поставить точку на модели в нужном месте.',
                text_31: 'Вы можете визуализировать смещения и деформации модели с помощью следующего инструмента:',
                text_32: 'В конце расчета вы можете получить отчет, нажав на кнопку "Результат" в верхней части экрана.',
                text_33: 'В дополнение к возможности импортировать результаты расчетов, можно получить отчет в формате PDF.',
                text_34: '"Показать задачу на доске" откроет вид модели без элементов пользовательского интерфейса на новой вкладке.',
                text_35: '"Загрузить Result.vtu" импортирует файл для удобного использования результатов расчета на вашем сайте.',
                text_36: 'При необходимости изменить или скорректировать характеристику материала или параметры наложения ГУ. После корректировки настроек необходимо нажать кнопку «Пересчитать результат»',
                text_37: 'На странице проектов вы можете создавать новые проекты, а так же вносить в изменение уже существующих. Чтобы создать новый проект, нажмите кнопку создать в левом верхнем углу.',
                text_38: 'Проект – одна, или несколько моделей, объединенных по тому или иному принципу. Проекты используются для удобной сортировки ваших моделей. Чтобы переименовать проекты, нажмите на три точки справа от названия проекта.',
                text_39: 'Вызванное контекстное меню предлагает переименовать проект, изменить права доступа, а также удалить проект. «Изменить проект» вызывает модальное окно.',
                text_40: 'С его помощью можно переименовать проект, добавить описание, сделать проект публичным, а также изменить приоритетную систему единиц измерения для проекта. После того, как все необходимые изменения будут внесены, достаточно нажать кнопку «Обновить», чтобы внесенные изменения были применены к проекту.',
                text_41: 'Чтобы расширить доступ к проекту для других лиц нужно нажать «Поделиться проектом».',
                text_42: 'В открывшимся окне необходимо в поле для ввода указать пользователя, который получит доступ до проекта. Флаг «Read only» указывает на то, может ли пользователь, который получит доступ вносить изменения и редактировать проект. Если флаг активен, пользователь, получивший доступ, сможет только просматривать результаты. Зеленая кнопка в правом нижнем углу модального окна отправит ссылку-приглашение указанному пользователю.',
                text_43: 'Чтобы удалить проекты, нажмите на три точки справа от названия проекта.',
                text_44: '«Удалить проект» вызовет модальное окно с запросом на удаление проекта. Если данное действие подтвердить, проект и все, что он в себя включает, будет удалено.',
                text_45: 'Добавить модель в проект можно, воспользовавшись карточкой загрузки. Для этого необходимо предварительно загрузить модель на свой компьютер. Нажать на карточку загрузки и выбрать необходимый файл.',
                text_46: 'Обратите внимание, что модель должна соответствовать расширениям, с которыми работает Prove.design. Альтернативно, для загрузки модели можно воспользоваться drag\'n\'drop и перенести файл модели в область проекта.',
                text_47: 'В нем можно указать название, а так же выбрать систему единиц измерения для модели. По умолчанию, система единиц измерения будет соответствовать системе единиц измерения проекта. Но при необходимости, систему единиц измерения можно задавать индивидуально для каждой модели. Завершением создания модели будет нажатие клавиши «Создать»',
                text_48: 'После добавления модели, через некоторое время будет сгенерирована «Превью» модели, после чего можно будет открыть модель в расчетном комплексе.',
                text_49: 'В правом верхнем углу карточки нажмите кнопку троеточия. В появившемся контекстном меню выберете поле «Изменить модель». Переименуйте модель в появившемся модальном окне, после чего нажмите «Применить»',
                text_50: 'В открывшемся окне необходимо указать в поле ввода пользователя, который получит доступ к проекту. Флаг "Только для чтения" указывает, может ли пользователь, получивший доступ, вносить изменения и редактировать проект. Если флаг включен, пользователь, имеющий доступ, сможет просматривать только результаты. Зеленая кнопка в правом нижнем углу модального окна отправит ссылку с приглашением указанному пользователю.',
                text_51: 'В правом верхнем углу карточки нажмите кнопку троеточия. В появившемся контекстном меню выберете поле «Копировать модель». Копия модели появится в проекте с индексом (2)',
                text_52: 'Если вам нужно переместить модель из одного проекта в другой, используйте перетаскивание. Для этого захватите курсором нужную модель и, не отпуская кнопку мыши, перенесите модель в нужный проект, затем отпустите кнопку мыши. Модель будет перенесена из одного проекта в другой. При переносе все расчеты модели будут сохранены.',
                text_53: 'В правом верхнем углу карточки нажмите кнопку с многоточием. В появившемся контекстном меню выберите поле "Удалить модель". В появившемся диалоговом окне вы должны подтвердить действие.',

            }
        },
        tutorial: {
            tutorial: 'Руководство'
        },
        faq: {
            faq: 'FAQ',
            title: {
                title_1: 'Что такое',
                title_2: 'Какие расчеты можно произвести?',
                title_3: 'Какие CAD форматы ',
                title_4: 'поддерживает',
                title_5: 'Какой механизм вычисления используется в ',
                title_6: 'Для кого',
                title_7: 'подходит',
                title_8: 'Что мне нужно знать о структурном анализе?',
                title_9: 'Как мне начать с',
                title_10: 'Могу ли я увидеть примеры вычислений?',
                title_11: 'Как поделиться результатами расчетов с коллегами или клиентами?',
                title_12: 'Как я могу получить результаты расчета?',
                title_13: 'Где я могу прочитать руководство пользователя?',
                title_14: 'Как с нами связаться?',
            },
            text: {
                text_1: 'это облачный CAE сервис. С его помощью, можно произвести расчет механического анализа ваших инженерных решений.',
                text_2: 'позволяет производить рачсет статических напряжений, а также модальный и термостатические анализы.',
                text_3: 'Какие преимущества ',
                text_4: ' дает пользователям',
                text_5: 'Не требует установки программного обеспечения на ваше устройство.',
                text_6: 'Вам не нужно высокопроизводительное устройство. Все расчеты производятся на мощных серверах. Для комфортного использования вам нужно только стабильное подключение к Интернету.',
                text_7: 'Вы можете выполнять вычисления, а также просматривать результаты вычислений с разных устройств. Все, что вам нужно сделать, это предоставить права доступа к желаемому проекту.',
                text_8: 'Удобный и интуитивно понятный интерфейс, адаптированный для использования с мобильными устройствами с небольшими размерами экрана.',
                text_9: 'Сервис поддерживает наиболее распространеные CAD форматы, такие как .stp, .sat, .stl, .igs.',
                text_10: 'Prove.design использует вычислительное ядро',
                text_11: 'програмные пакеты CAE Fidesys.',
                text_12: 'Наш сервис подходит для тех, кому необходимо произвести простые расчеты для анализа характеристик моделей. Например, если вам нужно рассчитать статические напряжения детали, которую вы спроектировали для печати на 3D-принтере. Также,',
                text_13: 'подходит для составления отчетов и статей, для выполнения воспитательной работы, в качестве демонстрационных и обучающих материалов и т.д.',
                text_14: 'Мы создали упрощенный процесс моделирования, чтобы свести к минимуму необходимые знания структурного анализа. Оптимизированный рабочий процесс включает интеллектуальную автоматизацию, основанную на настройках проекта, для автоматизации рутинных операций. Процесс состоит из простых шагов',
                text_15: 'Создает проект (загружает модель, сетку и задает свойства материала на основе значений проекта по умолчанию)',
                text_16: 'Устанавливает граничные условия (нагрузки и ограничения)',
                text_17: 'Выполняет анализ, и',
                text_18: 'Рассматривает результаты.',
                text_19: 'Для получения дополнительной информации, пожалуйста, обратитесь к технической документации, руководствам и примерам, представленным на сайте.',
                text_20: 'Чтобы начать, просто зарегистрируйтесь. Даже если у вас еще нет никаких моделей САПР, вы можете изучить наш сервис, используя встроенную библиотеку моделей.',
                text_21: 'Вы можете ознакомиться с примерами расчетов в разделе технической документации, ссылки на которую расположены в нижнем колонтитуле сайта. Также в этом разделе представлены обучающие видеоролики. Мы постарались подробно описать нюансы выполнения анализа, чтобы вы могли быстро приступить к проверке своих проектов.',
                text_22: 'В настройках проекта вы можете найти форму для предоставления доступа другим пользователям. Для этого необходимо указать адрес электронной почты пользователя, которому вы хотите предоставить доступ. Любой, кто получит письмо и перейдет по ссылке, сможет просмотреть результат вашего анализа. Для этого не требуется регистрация.',
                text_23: 'Результаты расчетов могут быть экспортированы в популярных форматах САПР на ваш компьютер. Вы также можете получить сгенерированный отчет в формате PDF для каждого расчета. Если вам нужно добавить результат вычислений к вашему ресурсу, то можно получить vtu-модель. Расчет в этом формате удобно интегрирован в сторонние веб-ресурсы.',
                text_24: 'Вы можете ознакомиться с подробной документацией, описывающей все этапы использования нашего сервиса, перейдя по ссылке в нижнем колонтитуле сайта.',
                text_25: 'Если у вас есть какие-либо вопросы, технические проблемы или вы хотите оставить нам пожелания, вы можете написать нам на почту, которую вы найдете в нижнем колонтитуле сайта, нажав на ссылку "связаться с нами". Вы также можете использовать виджет для общения, который статически закреплен в правом нижнем углу.',

            }
        },
        examples: {
            examples: 'Примеры',
            title: 'Расчет простой балки.',
            text1: 'Перед началом расчета необходимо импортировать модель. Для этого мы будем использовать форму загрузки.',
            text2: 'В открывшемся окне выберите нужный файл.',
            text3: 'Появившееся модальное окно позволяет произвести первоначальные настройки модели. В нем мы можем наблюдать за ходом загрузки модели. Также есть возможность переименовать модель и выбрать нужную систему единиц.',
            text4: 'Выходим из системы единиц СИ, нажимаем кнопку "Создать".',
            text5: 'Сервер начнет генерировать превью импортированной модели. Обычно это занимает несколько секунд. Процесс не требует ожидания, и вы можете сразу открыть проект.',
            text6: 'Тем временем превью готово. Вы можете открыть проект.',
            text7: 'Перед нами открывается рабочее пространство. Подробнее о том, какие инструменты есть на экране, вы можете узнать в разделе технической документации.',
            text8: 'У нас есть модель луча. Обратите внимание, что сетка конечных элементов уже создана.',
            text9: 'Для начала ознакомимся с характеристиками модели. Для этого перейдите в подраздел левого меню «Geometry & Mesh». ',
            text10: 'Итак, луч состоит из одного объема, шести граней, двенадцати ребер и восьми вершин. При сетке конечных элементов средней крупности модель содержит 1269 элементов. И вы также можете убедиться, что размеры импортированной модели соответствуют вашим потребностям.',
            text11: 'Мы также можем изменить шероховатость меша. Если его уменьшить, количество элементов в модели увеличится. Расчет более точный, но займет больше времени и потребует большей вычислительной мощности. Если мы уменьшим грубость сетки, то добьемся обратного эффекта.',
            text12: 'Для нашего расчета сетки средней крупности будет достаточно.',
            text13: 'Начнем расчеты.',
            text14: 'Перейдем в подраздел "Материалы и БК"',
            text15: 'Добавим немного материала. Для этого откройте поле «Добавить материал».',
            text16: 'В открывшемся поле мы видим поле для настройки характеристик материала. В верхнем поле ввода можно переименовать материал. Символ «*» в поле «Маркеры» означает, что материал будет применен ко всем объемам модели. Также можно выбрать материал из библиотеки материалов. Это справедливо для самых распространенных материалов. Откройте библиотеку, нажав на ссылку «Импортировать из библиотеки материалов».',
            text17: 'Для нашей балки мы выберем сталь. Вы можете выбрать любой другой материал, либо гибко настроить характеристики нужного вам материала в виде настроек. Нажмите «Применить».',
            text18: 'Перейдем к установке граничных условий.',
            text19: 'В раскрывающемся меню перечислены доступные граничные условия. На одну модель можно установить неограниченное количество ГУ.',
            text20: 'Прежде всего, добавим запрет движения в конец луча.',
            text21: 'Выберите "Смещение". Далее вам нужно выбрать объект привязки. На данный момент сервис поддерживает три возможных типа фиксации ГУ: объем, край, точка. В нашем расчете мы будем использовать грань для фиксации.',
            text22: 'На самой модели мышкой выбираем грани, на которые хотим применить ГУ. После выбора лицо станет красным, а идентификатор лица появится в поле «Маркеры».',
            text23: 'Запретить движение по всем трем осям. Нажмите Применить.',
            text24: 'Далее добавим еще одно граничное условие. Выберите «Гравитация» в поле «Добавить граничное условие»',
            text25: 'Гравитация применяется ко всему объему модели. Выбор значений и объема осуществляется автоматически. Нажмите «Применить»',
            text26: 'Далее приложите усилие к другому концу балки. Для этого в поле «Добавить граничное условие» выберите значение «Распределенный фарс»',
            text27: 'Давайте повернем балку другим концом с помощью мыши. Выберем необходимое ребро. Установим силу в направлении оси X на 200 ньютонов.',
            text28: 'Нажмите применить.',
            text29: 'Итак, теперь мы добавили все необходимые ГУ и можем посмотреть их список.',
            text30: 'Вы можете добавить другие ГУ.',
            text31: 'Заканчивая подготовку к расчетам, обратим внимание на поле "Тип анализа". Здесь вы можете выбрать необходимый тип анализа. Пока оставляем статический анализ',
            text32: 'Все готово для начала расчета. Нажмите «Начать расчет». По завершении расчета вся необходимая информация на экране обновится и отобразятся результаты расчета.',
            text33: 'Результаты готовы! Давайте познакомимся с ними.',
            text34: 'В подменю слева вы можете отобразить максимальное и минимальное значения. А также увидеть значения в любой точке. Для этого выбираем «Взять щупы», а затем нажимаем на интересующую нас точку на модели.',
            text35: 'Поле "Деформация" помогает визуализировать пластическую деформацию.',
            text36: 'Шкала связывает значения, указанные в верхней части шкалы, с цветом для более наглядного анализа. Можно ограничить максимальное и минимальное значения. Изменение визуальной составляющей результатов расчета на модели происходит автоматически.',
            text37: 'Давайте уменьшим максимальное значение и заметим, что максимальные напряжения достигаются на большей поверхности модели.',
            text38: 'Получить результаты расчета.',
            text39: 'В верхнем меню выберите "Скачать отчет.pdf"',
            text40: 'Вы можете сами прочитать этот отчет.',
            text41: 'Также вы можете получить возможность просмотра анализа без посторонних интерфейсов, а также возможность поделиться результатами на своем ресурсе, нажав "Показать задачу на доске"',
        }
    }
}