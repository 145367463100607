<template>
    <div>
        <router-view/>
        <div class="config" >v{{ config.VERSION }} </div>
    </div>


</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters('net', ['fetchApi']),
        ...mapGetters(['user', 'config', 'lang']),
    },
    methods: {
        ...mapActions('net', ['load_urls_map']),
        ...mapActions(['load_user', 'load_config']),
    },
    created() {
        this.load_urls_map()
            .then(this.load_config)
            .then(this.load_user)
            .then(() => {
                let {name} = this.$router.history.current
                if(name === 'projects'){
                    if(!this.user.auth){
                        window.location.replace('/')
                    }else if(!this.user.confirm){
                        window.location.replace('/unconfirmed')
                    }else if(!this.user.survey){
                        window.location.replace('/survey')
                    }
                }else if(name === 'unconfirmed'){
                    if(!this.user.auth){
                        window.location.replace('/')
                    }else if(this.user.confirm){
                        window.location.replace('/projects')
                    }
                }else if(name === 'survey'){
                    if(!this.user.auth){
                        window.location.replace('/')
                    }else if(this.user.survey) {
                        window.location.replace('/survey')
                    }
                }else if(name === 'logout'){
                    this.fetchApi('logout_api').then(() => {
                        window.location.replace('/')
                    })
                }else if (name === 'profile'){
                    if(!this.user.auth){
                        window.location.replace('/')
                    }
                }else if (name === 'home' ||
                    name === 'login' ||
                    name === 'forgot'){
                    if (this.user.auth) {
                        window.location.replace('/projects')
                    }
                }

                if(this.config.IS_PRODUCTION){
                    if (name === 'home' || name === 'projects' || name === 'help'){
                        window.replainSettings = {id: this.config.REPLAIN_ID};
                        (function (u) {
                            var s = document.createElement('script');
                            s.type = 'text/javascript';
                            s.async = true;
                            s.src = u;
                            var x = document.getElementsByTagName('script')[0];
                            x.parentNode.insertBefore(s, x);
                        })('https://widget.replain.cc/dist/client.js');
                    }
                }

                this.$i18n.locale = this.lang;
            })
    },
}

</script>

<style>
.config{
    position:fixed; bottom: 10px; right:10px; z-index:1000;font-size:12px;
}
</style>