import BaseModel from '@/models/Model';
import EntityTypes from '@/models/EntityTypes';


export default class Result extends BaseModel {
    /** @param {Object=} data */
    constructor(data) {
        super(data)
    }

    update(data){
        super.update(data)

        /** @type {string} */
        this.title = this.column(String, 'title', data)
        /** @type {boolean} */
        this.actual = this.column(Boolean, 'actual', data)
        /** @type {Date} */
        this.created = this.column(Date, 'created', data)
        /** @type {Date} */
        this.modified = this.column(Date, 'modified', data)
        /** @type {string} */
        this.analysis_type = this.column(String, 'analysis_type', data)

        /** @type {Object} */
        this.attributes = this.column(Object, 'attributes', data)

        /** @type {Object.<Entity>} */
        this.conditions = {}

        if (data.conditions) {
            for (let id in data.conditions) {
                this.conditions[id] = new (EntityTypes[data.conditions[id].entity_type])(data.conditions[id])
            }
        }

        /** @type {string} */
        this.description = this.column(String, 'description', data)

        /** @type {Object} */
        this.properties = this.column(Object, 'properties', data)
    }
}