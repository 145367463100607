import User from "@/models/User";

export default {
    namespaced: false,
    state: {
        user: new User({anonim:true})
    },
    getters: {
        user: state => state.user,
    },
    mutations: {
        set_user(state, data) {
            state.user = new User(data)
        }
    },
    actions: {
        load_user(context) {
            return context.getters['net/fetchJson']('user_api')
                .then(data => context.commit('set_user', data)).catch(
                    () => {}
                )
        },
    }
}