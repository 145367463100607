export default {
    state: {
        config: {
            'VK_CLIENT_ID': '',
            'GOOGLE_CLIENT_ID': '',
            'FACEBOOK_CLIENT_ID': '',
            'MAIN_INTRO': '',
            'MAIN_VIDEO': '',
            'RECAPTCHA_SITE_KEY': '',
            'SITE_NAME': '',
            'MAIN_INTRO_SRC': '',
            'VERSION':'1.3.3',
            'IS_PRODUCTION': false,
            'REPLAIN_ID': ''
        },
        theme: localStorage.getItem('theme') || 'dark',
        lang: localStorage.getItem('lang') || 'en',
    },
    getters: {
        config: state => state.config,
        theme: state => state.config,
        lang: state => state.lang,
        L: state => term => {
            if (state.lang in term){
                return term[state.lang]
            }else if ('en' in term){
                return term['en']
            }else{
                return ''
            }
        }
    },
    mutations: {
        set_config(state, config) {
            state.config = {...state.config, ...config}
        },
        set_theme(state, value){
            localStorage.setItem('theme', value);
            state.theme = value
        },
        set_lang(state, value){
            localStorage.setItem('lang', value);
            state.lang = value
        },
    },
    actions: {
        load_config(context) {
            return context.getters['net/fetchJson']('config_api')
                .then(data => context.commit('set_config', data))
        }
    }
}