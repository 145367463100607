export default {
    state: {
        errors: []
    },

    getters: {
        error_array: s => s.errors,
        error_is: s => s.errors.length > 0,
    },

    mutations: {
        error_add: (state, error) => {
            state.errors.push(`${error}`);
            console.error(error)
        },
        error_clean: (state) => {
            state.errors = [];
        }
    },
};