import Daemon from "@/utils/Daemon";

const POLL_DELAY = 0.5e3;
const PING_DELAY = 1e4;
const POLL_FACTOR = 1.2;
export default {
    state: {
        mode: '',
        showElems: {
            meshLine: true,
            scaleBar: false,
            axes: true,
            legend: true,
            console: false,
            inside: false,
            materialLibrary: false,
            extremesProbe: false,
            playAnimation: false,
            internalCurves: false
        },

        pingProcess: null,
        status: {},

        loaded: {
            geom: false,
            mesh: false,
            calc: false
        },

        balance: 0,

        allowedMTypes: ['node', 'surface', 'volume'],
    },
    getters: {

        mode: s => s.mode,
        showMeshLine: s => s.showElems.meshLine && s.loaded['mesh'],
        showScaleBar: s => (s.showElems.scaleBar && s.loaded['geom']),
        showAxes: s => s.showElems.axes,
        showMaterialLibrary: s => s.showElems.materialLibrary,
        showInside: s => s.showElems.inside,
        showLegend: s => s.showElems.legend && s.mode === 'calc',
        showConsole: s => s.showElems.console,
        showExtremesProbe: s => s.showElems.extremesProbe,
        showInternalCurves: s => s.showElems.internalCurves,
        playAnimation: s => s.showElems.playAnimation,
        loaded: s => s.loaded,

        buildingMode: s => {
            if (!s.loaded['geom'])
                return 'geom'

            if (!s.loaded['mesh'])
                return 'mesh'

            if (s.mode === 'geom') return 'mesh'
            if (s.mode === 'mesh') return 'calc'
            if (s.mode === 'calc') return 'calc'
        },

        builded: state => {
            let builded = {}
            for (let mode in state.status)
                builded[mode] = state.status[mode].success || false
            return builded
        },

        building: state => {
            let building = {}
            for (let mode in state.status)
                building[mode] = state.status[mode].active || false
            return building
        },

        balance: s => s.balance,
        status: s => s.status,
    },
    mutations: {

        set_loaded(state, loaded) {
            state.loaded = loaded
        },

        set_status(state, status) {
            state.status = status
        },

        pingProcess(state, pingProcess) {
            state.pingProcess = pingProcess
        },

        toggle: (state, element) => {
            state.showElems[element] = !state.showElems[element];
        },

        set_mode: (state, mode) => state.mode = mode,

        set_balance: (state, balance) => state.balance = balance,
    },

    actions: {

        start_ping(context) {
            let pingProcess = new Daemon(() => context.dispatch('ping'), PING_DELAY)
            pingProcess.run()
            context.commit('pingProcess', pingProcess)
        },

        kick_ping(context) {
            const pingProcess = context.state.pingProcess

            pingProcess.stop()

            let i = 0
            setTimeout(function tick() {
                i += 1
                if (i > 10) {
                    pingProcess.run()
                    return
                }
                context.dispatch('ping')
                setTimeout(tick, Math.min(POLL_DELAY * Math.pow(POLL_FACTOR, i), PING_DELAY))
            }, POLL_DELAY);
        },

        ping(context) {
            return context.getters['net/fetchJson']('ping_api')
                .then(ping => {
                    context.commit('set_balance', ping.balance)

                    for (let mode in ping.status) {
                        if (mode === 'prev') continue
                        let stat = ping.status[mode]

                        let prefix =
                            mode === 'geom' ? 'Geometry' :
                                mode === 'mesh' ? 'Mesh' :
                                    mode === 'calc' ? 'Calculation' :
                                        ''
                        let text = `${prefix}: ${stat.message}`
                        // context.commit('say', {text, type:stat.status, id:stat.id})
                    }
                    context.commit('set_status', ping.status)

                    ping.messages.forEach(message => context.dispatch('say', message))
                })
        },
    }
}