import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('./views/Home.vue')
    },
    {
        path: '/projects/',
        name: 'projects',
        component: () => import('./views/Projects.vue')
    },
    {
        path: '/workbench/:tid',
        name: 'workbench',
        component: () => import('./views/Workbench.vue')
    },
    {
        path: '/result/:tid',
        name: 'result',
        component: () => import('./views/Whiteboard.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/auth/Login.vue')
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('./views/auth/Logout.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('./views/Profile.vue')
    },
    {
        path: '/survey',
        name: 'survey',
        component: () => import('./views/auth/Survey.vue')
    },
    {
        path: '/restore/:token',
        name: 'restore',
        component: () => import('./views/auth/Restore.vue')
    },
    {
        path: '/forgot',
        name: 'forgot',
        component: () => import('./views/auth/Forgot.vue')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('./views/help/Privacy.vue')
    },
    {
        path: '/unconfirmed',
        name: 'unconfirmed',
        component: () => import('./views/auth/Unconfirmed.vue')
    },
    {
        path: '/examples',
        name: 'examples',
        component: () => import('./views/help/Examples.vue')
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import('./views/help/Faq.vue')
    },
    {
        path: '/help',
        name: 'help',
        component: () => import('./views/help/Help.vue')
    },
    {
        path: '/manual',
        name: 'manual',
        component: () => import('./views/help/Manual.vue')
    },
    {
        path: '/tutorial',
        name: 'tutorial',
        component: () => import('./views/help/Tutorial.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
