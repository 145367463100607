import Fetch from '@/utils/Fetch';
import Storage from "@/utils/Storage";

/** @typedef {Object.<string, string>} Dict */

const state = {
    urls_map: {},

    /** @type {Dict} */
    replacement: {},
    storage: new Storage('FidesysOnline'),
    fetch: (new Fetch('FidesysOnline')).fetch
}

const getters = {

    /** Функция, возвращающая по ключу готовую ссылку к API
     * @param {state} state
     * @return {function(
     *      input:string,
     *      replacement?:Dict
     * ): string} */
    url: state => (input, replacement) => {

        replacement = {...state.replacement, ...replacement}

        let url = state.urls_map[input] || input

        for (let key in replacement) {
            url = url.replace(new RegExp('<' + key + '>'), replacement[key])
        }
        return url
    },

    /** Стандартный запрос к серверу
     * @param {state} state
     * @param {getters} getters
     * @return {function (
     *      input:string,
     *      method?:string,
     *      body?: object
     * ): Promise<Response>} */
    fetchApi: (state, getters) => (input, method = 'GET', body = {}) => {
        return state.fetch(getters.url(input), method, body)
    },

    /** Запрос к серверу с последующим json-преобразование
     * @param {state} state
     * @param getters
     * @return {function(
     *      input:string,
     *      method?:string,
     *      body?
     * ): Promise<Object>} */
    fetchJson: (state, getters) => (input, method = 'GET', body = {}) => {
        return state.fetch(getters.url(input), method, body).then(
            response => {
                if (!response.ok) throw response;
                return response.status === 204/*No Content*/
                    ? Promise.resolve({})
                    : response.json();
            }
        );
    },

    /** Запрос к серверу с последующим json-преобразование
     * @param {state} state
     * @param getters
     * @return {function(
     *      input:string
     * ): Promise<ArrayBuffer>} */
    fetchBuffer: (state, getters) => (input) => {
        let url = getters.url(input)
        return state.storage.restore(url).then(() => {
            // TODO кажется тут надо вставить возврат найденного значения
            throw null;
        }).catch(storage => state.fetch(url, 'GET', {}).then(
            response => {
                const promise = response.arrayBuffer();
                storage && storage.store(url, promise
                    .then(buff => {
                        const {byteLength} = buff;
                        const chunkLength = 1024 * 64/*Kb*/;
                        let text = '';
                        for (let o = 0; o < byteLength; o += chunkLength) {
                            const chunk = new Uint8Array(buff, o,
                                Math.min(chunkLength, byteLength - o),
                            );
                            text += String.fromCharCode(...chunk);
                        }
                        return text;
                    }),
                );
                return promise;
            }
        ))
    }
};

const mutations = {
    /** @param {state} state
     * @param {Dict} urls_map */
    update_urls_map(state, urls_map) {
        state.urls_map = {...state.urls_map, ...urls_map}
    },
    update_url_replacement(state, replacement) {
        state.replacement = {...state.replacement, ...replacement}
    }
};

const actions = {
    /** @param context
     * @return Promise */
    load_urls_map(context) {
        return context.state.fetch('/api/map', 'GET', {})
            .then(response => response.json())
            .then(urls_map => context.commit('update_urls_map', urls_map))
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
