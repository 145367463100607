import Project from "@/models/Project";
import {Units, units_system_list} from "@/lib/Units";
import Task from "@/models/Task";
import Vue from "vue";

export default {
    namespaced: false,
    state: {
        /** @type {Task} */
        task: new Task(),
        /** @type {Project} */
        project: new Project(),
    },
    getters: {
        task: state => state.task,
        units: state => {
            return Units[state.task.unit_system]
        },
        unit_length: state => {
            return Units[state.task.unit_system].length
        },
        all_units: _ => Units,
        unit_system: state => state.task.unit_system,
        units_system_list: _ => units_system_list,
        scale: state => state.task.scale,

        properties: state => state.task.properties,

        statistic(state, getters) {
            let filter = [
                'volume', 'surface', 'curve', 'vertex', 'coarseness',
                'node', 'element', 'bounding_box',
                'total_volume', 'total_area'
            ]

            let scale = getters.scale

            let statistic = {}
            for (let key of filter) {
                if (key in state.task.properties) {
                    if(key === 'bounding_box'){
                        statistic['size_x'] = (state.task.properties[key][2]*scale).toExponential(2)
                        statistic['size_y'] = (state.task.properties[key][5]*scale).toExponential(2)
                        statistic['size_z'] = (state.task.properties[key][8]*scale).toExponential(2)
                    }else if(key === 'total_volume'){
                        statistic['total_volume'] = (state.task.properties[key]*scale*scale*scale).toExponential(2)
                    }else if(key === 'total_area'){
                        statistic['total_area'] = (state.task.properties[key]*scale*scale).toExponential(2)
                    }else{
                        statistic[key] = state.task.properties[key]
                    }
                }
            }
            return statistic
        },

        project: state => state.project,

        prop: state => (property, def = null) => {
            return state.task.properties.hasOwnProperty(property) ? state.task.properties[property] : def
        },

        readonly: state => state.project.readonly,
        writeable: state => !state.project.readonly,
    },
    mutations: {
        set_task(state, data) {
            let task = new Task(data);
            state.task = task
        },

        set_task_attr(state, attr) {
            for (let key in attr) {
                Vue.set(state.task, key, attr[key])
            }
        },

        set_task_prop(state, prop) {
            Vue.set(state.task, 'properties',
                Object.assign(state.task.properties, prop))
        },

        set_scale(state, scale) {
            state.task.scale = scale
        },

        set_unit_system(state, unit_system){
            state.task.unit_system = unit_system
        },

        set_project(state, project) {
            state.project = new Project(project);
        }
    },
    actions: {
        save_scale(context, scale){
            if(scale && scale>0){
                context.commit('set_scale', scale);
                context.getters['net/fetchApi']("properties_api", 'PUT', {scale})
            }
        },

        save_unit_system(context, unit_system){
            context.commit('set_unit_system', unit_system);
            context.getters['net/fetchApi']("properties_api", 'PUT', {unit_system})
        },

        save_task_attr(context, attr) {
            context.getters['net/fetchApi']('task_api', 'PUT', attr)
                .then(response => {
                    if (response.status !== 200) {
                        throw response;
                    }
                    context.commit('set_task_attr', attr)
                })
                .catch(response => {
                    response.text().then(text => context.commit('error_add', text))
                })
        },

        load_task(context) {
            return context.getters['net/fetchJson']('task_api')
                .then(data => {
                    context.commit('set_task', data)
                    context.commit('net/update_url_replacement', {pid: data.project.id})
                })
        },

        load_project(context) {
            return context.getters['net/fetchJson']('project_api')
                .then(data => context.commit('set_project', data))
        },

        load_properties(context) {
            return context.getters['net/fetchJson']('properties_api')
                .then(data => context.commit('set_task_prop', data))
        },

    }
}