import './function';
// TODO Проверить, не можем ли мы обойтись без этого нативным js

Object.assign(Array, {
    /**
     * @param {Array|TypedArray} arr1
     * @param {Array|TypedArray} arr2
     * @return {Array}
     */
    difference(arr1, arr2) {
        return this.unique(arr1, ...arr2);
    },

    /**
     * @param {Array|TypedArray} arr1
     * @param {Array|TypedArray} arr2
     * @return {boolean}
     */
    equals: (arr1, arr2) => {
        if (arr1 === arr2) {
            return true;
        }

        if (arr1.length !== arr2.length) {
            return false;
        }

        let equals = true;
        arr1.forEach(item => {
            if (arr2.indexOf(item) < 0) {
                return equals = false;
            }
        });
        return equals;
    },

    /**
     * @param {Array} target
     * @return {number[]}
     */
    numbers: target => target.map(Number),

    /**
     * @param {number} max
     * @param {number=} opt_min [default: 0]
     * @param {number=} opt_step [default: 1]
     * @return {number[]}
     */
    range: (max, opt_min, opt_step) => {
        const target = [];
        opt_step || (opt_step = 1);

        for (opt_min || (opt_min = 0); opt_min <= max; opt_min += opt_step) {
            target.push(opt_min);
        }

        return target;
    },

    /**
     * @param {ArrayLike|IArguments} target
     * @param {number=} opt_start
     * @param {number=} opt_end
     * @return {Array}
     */
    slice(target, opt_start, opt_end) {
        return this.prototype.slice.call(...arguments);
    },

    /**
     * @param {Array|TypedArray} target
     * @param {...*} arguments excluded values
     * @return {Array}
     */
    unique(target) {
        const items = [];
        const excluded = 1 < arguments.length
            ? this.slice(arguments, 1)
            : items
        ;

        target.forEach(item => {
            excluded.indexOf(item) < 0 && items.push(item);
        });

        return items;
    },
});

Array.replacePrototype('filter', origin =>
    /**
     * @param {(function(this: T[], value: T, index: number, scope: T[]):boolean)=} opt_cb
     * @param {?*} opt_scope
     * @see {Array.filter}
     */
    function (opt_cb, opt_scope) {
        return origin.call(this, opt_cb || (v => !!v), opt_scope);
    },
);


/**
 * @param {string} type
 * @param {number} offset
 * @param {number=} opt_len
 * @return {TypedArray}
 */
ArrayBuffer.prototype.toArray = function (type, offset = 0, opt_len) {
    const TypedArray = window[`${type}Array`];
    const array = new TypedArray(this, offset, opt_len);
    const view = new DataView(this);
    const value = () => view[`get${type}`](offset);

    const {length} = array;
    let i = 0;
    while (i < length && !array[i]) {
        ++i;
    }

    array[i] === value() || array.forEach((v, i) => {
        array[i] = value();
        offset += TypedArray.BYTES_PER_ELEMENT;
    });

    return array;
};
