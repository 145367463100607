import EntityTypes from "@/models/EntityTypes";
import '@/ext/object';
import '@/ext/string';
import '@/ext/array';
import Vue from "vue";

/**
 * @param {Object} props
 * @return {Entity}
 */
let create = (props) => {
    let Type = props.entity_type ? EntityTypes[props.entity_type] : EntityTypes.Entity
    return new Type(props);
}

/** переводит перечень маркеров в форму списка
 * @param state
 * @param markers {number[]|string}
 * @return {*[]|number[]}
 */
let markersToList = (state, markers) => {

    if (!state.markers_type) return []

    if (String.isString(markers)) {
        markers = markers.trim()
        markers = markers === '*' ?
            [...Array(state.count_el[state.markers_type]).keys()].map(x => x + 1) :
            markers.split(',')
    }

    if (!Array.isArray(markers)) return []

    markers = markers.map(Number).filter(x => !isNaN(x) && x > 0)

    if (state.count_el[state.markers_type]) {
        markers = markers.filter(x => x <= state.count_el[state.markers_type])
    }
    return markers

}

/** переводит перечень маркеров в форму строки
 * @param state
 * @param markers {number[]|string}
 * @return {string}
 */
let markersToStr = (state, markers) => {
    if (!state.markers_type) return ''

    if (typeof markers === "string") return markers

    if (!Array.isArray(markers)) return ''

    if (markers.length === 0) return ''

    if (state.markers_type in state.count_el) {
        let count = state.count_el[state.markers_type]
        if (Array.unique(state.markers).length >= count) return '*'
    }
    return markers.join(',') + ','
}

export default {

    state: {
        /** @type {Object.<string, Entity>} */
        conditions: {},
        /** @type {Entity} */
        current: null,
        markers: [],
        count_el: {
            surface: 0,
            volume: 0
        },
        /** @type {?string} */
        markers_type: null,
    },

    getters: {

        conditions(state) {
            return state.conditions
        },

        current(state) {
            return state.current
        },
        markers(state) {
            return state.markers
        },
        markers_type(state) {
            return state.markers_type
        },

        markers_count(state){
            if(state.markers_type && state.count_el[state.markers_type])
                return state.count_el[state.markers_type]
            return 0
        },

        hasBC: state => (entity_type) => {
            for (let key in state.conditions) {
                let condition = state.conditions[key]
                if (condition.entity_type === entity_type)
                    return true
            }
            return false
        }

    },

    mutations: {

        /** Загружаем древо
         * @param state
         * @param {Object.<string,object>} response */
        set_conditions(state, response) {
            state.conditions = Object.map(
                response, create
            )
        },

        set_current_value(state, value) {
            if (state.current) state.current = Object.assign(state.current, value)
        },

        /** Добавляем новое условие задачи в древо (переданного типа)
         * @param state
         * @param {string} entity_type */
        append_condition(state, entity_type) {
            let entity = create({entity_type, phantom: true})

            const name = entity.entity_type.split('.').join(' ');

            let indexes = [0]

            for (let uuid in state.conditions) {
                let condition = state.conditions[uuid]
                let r = (new RegExp(`^${name}\\s+(\\d+)$`)).exec(condition.name)
                if (r) indexes.push(Number(r[1]))
            }
            let n = Math.max(...indexes)

            entity = Object.assign(
                entity,
                {enabled: true},
                {name: `${name} ${(++n).pad()}`},
            )
            Vue.set(state.conditions, entity.uuid, entity)
            state.current = entity

            state.markers_type = entity.markers_type
            state.markers = markersToList(state, entity.markers)
        },

        /** Раскрываем древо на переданном элементе
         * @param state
         * @param {Entity} entity */
        expand_condition(state, entity) {
            state.current = entity
            state.markers_type = entity.markers_type
            state.markers = Array.unique(markersToList(state, entity.markers))
        },

        /** Закрываем всё древо
         * @param state
         * @param {Entity} entity */
        collapse_condition(state, entity) {
            if (!state.current) return
            if (!entity) return
            if (entity.phantom) Vue.delete(state.conditions, entity.uuid)
            if (entity.uuid === state.current.uuid) {
                state.current = null

                state.markers_type = null
                state.markers = []
            }
        },

        /** Удалеяем выбранный элемент
         * @param state
         * @param {Entity} entity */
        remove_condition(state, entity) {
            Vue.delete(state.conditions, entity.uuid)
        },

        set_markers(state, markers) {
            if (state.current) {
                state.markers = markersToList(state, markers)
                state.current.markers = markersToStr(state, markers)
            }
        },

        set_geometry_count(state, geometry) {
            let surfaces_count = 0
            for (let i in geometry) {
                surfaces_count += Object.keys(geometry[i]).length - 2
            }
            Vue.set(state.count_el, 'volume', Object.keys(geometry).length)
            Vue.set(state.count_el, 'surface', surfaces_count)
        },

        set_markers_type(state, markers_type) {
            if (state.current) {
                if (markers_type !== state.markers_type) {
                    state.markers = []
                    state.current.markers = ''
                    state.markers_type = markers_type
                    state.current.markers_type = markers_type
                }
            }
        },
    },
    actions: {

        load_conditions(context) {
            return context.getters['net/fetchJson']("condition_api")
                .then((data) => {
                    context.commit('set_conditions', data);
                });
        },
    }
}

