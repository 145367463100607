import './array';


/**
 * @param {*} v
 * @return {boolean}
 */
String.isString = v => typeof v === 'string';


Object.assign(String.prototype, {
    /**
     * @param {Object} fmt
     * @return {string}
     */
    format(fmt) {
        return Object.keys(fmt).reduce(
            (s, key) => s.replace(new RegExp(`%${key}%`, 'gi'), fmt[key]),
            this,
        );
    },

    /** @return {number[]} */
    numbers() {
        return Array.unique(Array.numbers(this.split(/\s*[,\s]\s*/)).filter())
            .sort((a, b) => a - b)
            ;
    },

    /**
     * @param {string|RegExp} s
     * @return {string}
     */
    remove(s) {
        return this.replace(s, '');
    },

    /** Переводит строку из кебаб-кейс в кемелКейс
     * @return {string} */
    resolveId() {
        return this
            .replace(/\s+/g, '-')
            .split('-')
            .map(s => s.ucfirst())
            .join('')
            ;
    },

    /** @return {string} */
    ucfirst() {
        return this[0].toUpperCase() + this.substr(1);
    },

    /**
     * @param {number=} opt_limit
     * @return {string[]}
     */
    words(opt_limit) {
        return this.split(/\s+/, opt_limit);
    },
});
