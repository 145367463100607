export default class {

    constructor(opt_sessionKey) {
        this.sessionKey_ = opt_sessionKey
    }

    getCookie(name) {
        name = name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1');

        const matches = document.cookie.match(
            new RegExp(`(?:^|;\\s*)${name}\\s*=\\s*([^;]*)`),
        );

        return matches && decodeURIComponent(matches[1]);
    }

    /**
     * @param {string} input
     * @param {string} method
     * @param {object} body
     * @return Promise<Response> */
    fetch(input, method, body) {
        body = body != null ? body : {}

        const headers = new Headers();
        headers.set('X-Requested-With', 'XMLHttpRequest');

        if (!method || /^(GET|HEAD)$/i.test(method)) {
            body = null;
        } else if (body) {
            if (typeof body == 'object') {
                body = JSON.stringify(body);
                headers.set(
                    'Content-Type',
                    'application/json; charset=UTF-8',
                );
            }
        }

        this.sessionKey_ && (
            session => session && headers.append(
                'Cookie',
                `${this.sessionKey_}=${session}`,
            )
        )(this.getCookie(this.sessionKey_));

        return fetch(input, {headers, method, body});
    }

}
