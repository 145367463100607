import net from '@/store/net';
import user from '@/store/user';
import config from '@/store/config';
import Vuex from 'vuex';
import Vue from "vue";
import main from "@/store/main";
import task from "@/store/task";
import error from "@/store/error";
import conditions from "@/store/conditions";
import terminal from "@/store/terminal";
import result from "@/store/result";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        net,
        user,
        config,
        main,
        task,
        error,
        conditions,
        terminal,
        result,
    },
})