import {v4 as uuid} from "uuid";

export default class Entity {

    get defaultValues() {
        return {x:0, y:0, z:0}
    }

    get defaultMarkers() {
        return ''
    }

    /** @param {Object=} opt_props */
    constructor(opt_props) {

        this.uuid = uuid();

        (markers => Object.defineProperty(
                this,
                'markers',
                {
                    enumerable: true,
                    get: () => markers,
                    set: value => markers = value,
                })
        )(this.defaultMarkers);

        (mtype => Object.defineProperty(
                this,
                'markers_type',
                {
                    enumerable: true,
                    get: () => mtype,
                    set: value => this.allowedMType(value) && (mtype = value)
                })
        )(this.defaultMType);

        this.name = ''
        this.entity_type = this.constructor.name
        this.phantom = false

        this.value = this.defaultValues
        Object.assign(this, opt_props);
    }


    /** @return {Entity.markerTypeEnum[]} */
    static get allowedMTypes() {
        // noinspection JSValidateTypes
        return Object.values(Entity.markerTypeEnum);
    }

    /** @param {function(Entity.markerTypeEnum)} cb */
    static eachMType(cb) {
        Object.keys(Entity.markerTypeEnum).forEach(cb);
    }

    /** @return {Entity.markerTypeEnum[]} */
    get allowedMTypes() {
        return this.constructor.allowedMTypes;
    }

    /** @return {Entity.markerTypeEnum} */
    get defaultMType() {
        return this.allowedMTypes.pop();
    }

    /** @return {string} */
    get entityName() {
        return this.name || this.entity_type;
    }

    /** @return {string[]} */
    get path() {
        return this.entity_type.split('.');
    }

    /**
     * @param {Entity.markerTypeEnum} mtype
     * @return {boolean}
     */
    allowedMType(mtype) {
        return 0 <= this.allowedMTypes.indexOf(mtype);
    }
}

/** @enum {string} */
Entity.markerTypeEnum = {
    node: 'node',
    facet: 'facet',
    surface: 'surface',
    volume: 'volume',
};
