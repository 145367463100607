import '@/ext/date';
import '@/ext/object';

/** @typedef {Record<string, *>} Dictionary */

class Unit {
    /**
     * Create unit fot unit system.
     * @param {{attr:string, name:string, scale:number, shift:number}} data
     */
    constructor(data) {
        this.attr = data.attr
        this.name = data.name
        this.scale = data.scale
        this.shift = data.shift
    }

    convert(value) {
        if (isNaN(Number(value))) return value
        return value * this.scale + this.shift
    }

    revert(value) {
        if (isNaN(Number(value))) return value
        return (value - this.shift) / this.scale
    }

    get changing() {
        return this.scale !== 1 || this.shift !== 0
    }
}

const UnitSystems = {
    si: {
        length: {name: "m", scale: 1.0, shift: 0.0},
        mass: {name: "kg", scale: 1.0, shift: 0.0,},
        force: {name: "N", scale: 1.0, shift: 0.0},
        time: {name: "sec", scale: 1.0, shift: 0.0},
        temperature: {name: "C", scale: 1.0, shift: 0.0},
        stress: {name: "Pa", scale: 1.0, shift: 0.0},
        density: {name: "kg/m^3", scale: 1.0, shift: 0.0},
        gravity: {name: "m/sec^2", scale: 1.0, shift: 0.0},
        angular_velocity: {name: "rad/sec", scale: 1.0, shift: 0.0},
        thermal_expansion: {name: "1/C", scale: 1.0, shift: 0.0},
        thermal_conductivity: {name: "W/(m-C)", scale: 1.0, shift: 0.0},
        specific_heat_capacity: {name: "J/(kg-C)", scale: 1.0, shift: 0.0},
        heat_transfer: {name: "W/(m^2-C)", scale: 1.0, shift: 0.0},
        heat_flux: {name: "W/(m^2)", scale: 1.0, shift: 0.0},
        empty: {name: "", scale: 1.0, shift: 0.0},
    },
    cgs: {
        length: {name: "cm", scale: 0.01, shift: 0.0},
        mass: {name: "g", scale: 0.001, shift: 0.0},
        force: {name: "dyne", scale: 0.00001, shift: 0.0},
        time: {name: "sec", scale: 1.0, shift: 0.0},
        temperature: {name: "C", scale: 1.0, shift: 0.0},
        stress: {name: "dyne/cm^2", scale: 0.1, shift: 0.0},
        density: {name: "g/cm^3", scale: 1e3, shift: 0.0},
        gravity: {name: "cm/sec^2", scale: 0.01, shift: 0.0},
        angular_velocity: {name: "rad/sec", scale: 1.0, shift: 0.0},
        thermal_expansion: {name: "1/C", scale: 1.0, shift: 0.0},
        thermal_conductivity: {name: "cW/(cm-C)", scale: 1.0, shift: 0.0},
        specific_heat_capacity: {name: "J/(g-C)", scale: 1e-3, shift: 0.0},
        heat_transfer: {name: "W/(m^2-K)", scale: 1.0, shift: 0.0},
        heat_flux: {name: "W/(m^2)", scale: 1.0, shift: 0.0},
        empty: {name: "", scale: 1.0, shift: 0.0},
    },
    mmns: {
        length: {name: "mm", scale: 1e-3, shift: 0.0},
        mass: {name: "t", scale: 1e3, shift: 0.0},
        force: {name: "N", scale: 1.0, shift: 0.0},
        time: {name: "sec", scale: 1.0, shift: 0.0},
        temperature: {name: "C", scale: 1.0, shift: 0.0},
        stress: {name: "MPa", scale: 1e6, shift: 0.0},
        density: {name: "t/mm^3", scale: 1e12, shift: 0.0},
        gravity: {name: "mm/sec^2", scale: 1e-3, shift: 0.0},
        angular_velocity: {name: "rad/sec", scale: 1.0, shift: 0.0},
        thermal_expansion: {name: "1/C", scale: 1.0, shift: 0.0},
        thermal_conductivity: {name: "mW/(mm-C)", scale: 1.0, shift: 0.0},
        specific_heat_capacity: {name: "mJ/(t-C)", scale: 1.0, shift: 0.0},
        heat_transfer: {name: "W/(m^2-K)", scale: 1.0, shift: 0.0},
        heat_flux: {name: "W/(m^2)", scale: 1.0, shift: 0.0},
        empty: {name: "", scale: 1.0, shift: 0.0},
    },
    fps: {
        length: {name: "ft", scale: 0.3048, shift: 0.0},
        mass: {name: "slug", scale: 14.59, shift: 0.0},
        force: {name: "lbf", scale: 4.448, shift: 0.0},
        time: {name: "sec", scale: 1.0, shift: 0.0},
        temperature: {name: "F", scale: 0.555555556, shift:  32.0},
        stress: {name: "lb/ft^2", scale: 47.9, shift: 0.0},
        density: {name: "slug/ft^3", scale: 515.0, shift: 0.0},
        gravity: {name: "ft/sec^2", scale: 0.3048, shift: 0.0},
        angular_velocity: {name: "rad/sec", scale: 1.0, shift: 0.0},
        thermal_expansion: {name: "1/F", scale: 1.8, shift: 0.0},
        thermal_conductivity: {name: "lbf/(sec-F)", scale: 8.007, shift: 0.0},
        specific_heat_capacity: {name: "Btu/(lb-F)", scale: 4187.0, shift: 0.0},
        heat_transfer: {name: "Btu/(h-ft^2-F)", scale: 5.678, shift: 0.0},
        heat_flux: {name: "W/(m^2)", scale: 1.0, shift: 0.0},
        empty: {name: "", scale: 1.0, shift: 0.0},
    },
    ips: {
        length: {name: "in", scale: 2.54e-2, shift: 0.0},
        mass: {name: "(lbf-sec^2)/in", scale: 175.1, shift: 0.0},
        force: {name: "lbf", scale: 4.448, shift: 0.0},
        time: {name: "sec", scale: 1.0, shift: 0.0},
        temperature: {name: "F", scale: 0.555555556, shift: 32.0},
        stress: {name: "psi", scale: 6890.0, shift: 0.0},
        density: {name: "lbf-sec^2/in^4", scale: 1.07e7, shift: 0.0},
        gravity: {name: "in/sec^2", scale: 2.54e-2, shift: 0.0},
        angular_velocity: {name: "rad/sec", scale: 1.0, shift: 0.0},
        thermal_expansion: {name: "1/F", scale: 1.8, shift: 0.0},
        thermal_conductivity: {name: "lbf/(sec-F)", scale: 8.007, shift: 0.0},
        specific_heat_capacity: {name: "Btu/(lb-F)", scale: 4187.0, shift: 0.0},
        heat_transfer: {name: "Btu/(h-ft^2-F)", scale: 5.678, shift: 0.0},
        heat_flux: {name: "W/(m^2)", scale: 1.0, shift: 0.0},
        empty: {name: "", scale: 1.0, shift: 0.0},
    }
}

const units_system_list = Object.keys(UnitSystems)

const Units = {}
for (let us in UnitSystems) {
    Units[us] = {}
    for (let attr in UnitSystems[us]) {
        Units[us][attr] = new Unit(Object.assign(UnitSystems[us][attr], {attr}))
    }
}

export {Units, Unit, units_system_list}