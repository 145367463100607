import Entity from '@/models/Entity';

export class Material extends Entity {

    get defaultMarkers() {
        return '*'
    }

    /** @override */
    get defaultValues() {
        return {
            youngs_modulus: 2e+11,
            poissons_ratio: 0.3,
            density: 8000,
            ultimate_stress: 4.5e+08,
            ultimate_strain: 0.4,
            yield_stress: 1.6e+08,
            specific_heat_capacity: 500,
            thermal_conductivity: 45,
            thermal_expansion_coefficient: 1.2e-05,
        }
    }

    get defaultMType() {
        return Entity.markerTypeEnum.volume;
    }

    /** @override */
    static get allowedMTypes() {
        return [Entity.markerTypeEnum.volume];
    }
}

export class Temperature extends Entity {

    get defaultValues() {
        return {
            temperature: 0,
        }
    }

    get defaultMType() {
        return Entity.markerTypeEnum.surface;
    }

    /** @override */
    static get allowedMTypes() {
        return [Entity.markerTypeEnum.surface, Entity.markerTypeEnum.node, Entity.markerTypeEnum.volume];
    }
}

export class Convection extends Entity {

    get defaultValues() {
        return {
            temperature: 0,
            heat_transfer: 0
        }
    }

    get defaultMType() {
        return Entity.markerTypeEnum.surface;
    }

    /** @override */
    static get allowedMTypes() {
        return [Entity.markerTypeEnum.surface, Entity.markerTypeEnum.node];
    }
}

export class HeatFlux extends Entity {

    get defaultValues() {
        return {magnitude: 0}
    }

    get defaultMType() {
        return Entity.markerTypeEnum.surface;
    }

    /** @override */
    static get allowedMTypes() {
        return [Entity.markerTypeEnum.surface, Entity.markerTypeEnum.node];
    }
}


export class Displacement extends Entity {

    get defaultMType() {
        return Entity.markerTypeEnum.surface;
    }

    /** @override */
    static get allowedMTypes() {
        return [Entity.markerTypeEnum.surface, Entity.markerTypeEnum.node, Entity.markerTypeEnum.volume];
    }
}

export class Fixed extends Entity {

    get defaultValues() {
        return {}
    }

    get defaultMType() {
        return Entity.markerTypeEnum.surface;
    }

    /** @override */
    static get allowedMTypes() {
        return [Entity.markerTypeEnum.surface, Entity.markerTypeEnum.node, Entity.markerTypeEnum.volume];
    }
}

export class DistributedForce extends Entity {

    /** @override */
    get defaultMType() {
        return Entity.markerTypeEnum.surface;
    }

    /** @override */
    static get allowedMTypes() {
        return [Entity.markerTypeEnum.surface, Entity.markerTypeEnum.node];
    }
}

export class AngularVelocity extends Entity {

    get defaultMarkers() {
        return '*'
    }

    /** @override */
    get defaultValues() {
        return {x: 0, y: 0, z: 0}
    }

    /** @override */
    static get allowedMTypes() {
        return [Entity.markerTypeEnum.volume];
    }

    /** @override */
    get defaultMType() {
        return Entity.markerTypeEnum.volume;
    }
}

export class Gravity extends Entity {

    get defaultMarkers() {
        return '*'
    }

    /** @override */
    get defaultValues() {
        return {x: null, y: null, z: -9.8}
    }

    /** @override */
    static get allowedMTypes() {
        return [Entity.markerTypeEnum.volume];
    }

    /** @override */
    get defaultMType() {
        return Entity.markerTypeEnum.volume;
    }
}

export class Pressure extends Entity {

    /** @override */
    get defaultValues() {
        return {magnitude: null}
    }

    /** @override */
    get defaultMType() {
        return Entity.markerTypeEnum.surface;
    }

    /** @override */
    static get allowedMTypes() {
        return [Entity.markerTypeEnum.surface, Entity.markerTypeEnum.node];
    }
}

export default  {
    Material,
    Temperature,
    Convection,
    HeatFlux,
    AngularVelocity,
    Displacement,
    Fixed,
    DistributedForce,
    Gravity,
    Pressure,
}