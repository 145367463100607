import '../ext/string';


export default class {
    /**
     * @param {Function|string} handler
     * @param {number} timeout
     */
    constructor(handler, timeout) {
        String.isString(handler) && (handler = window[handler]);

        if (!(handler instanceof Function)) {
            throw 'Bad task handler';
        }

        Object.assign(this, {
            handler_: handler,
            timeout_: timeout,
            timer_: 0,
        });
    }

    /** @return {boolean} */
    get running() {
        return !!this.timer_;
    }

    call() {
        return this.handler_();
    }

    /**
     * @param {number=} opt_timeout
     * @return {Object}
     */
    defer(opt_timeout) {
        this.stop();
        return setTimeout(this.handler_, opt_timeout || this.timeout_);
    }

    run() {
        this.stop();
        this.call();
        this.timer_ = setInterval(this.handler_, this.timeout_);
    }

    stop() {
        if (this.running) {
            clearInterval(this.timer_);
            this.timer_ = 0;
        }
    }
}
