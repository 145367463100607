import './number';


Object.assign(Date, {
    /** @return {number} */
    stamp: () => (new Date()).getTime(),

    /**
     * Gets time in HH:MM[:SS] format
     * @param {number} stamp UNIX-timestamp
     * @param {boolean=} opt_short if true it'll returns time without seconds
     * @return {string}
     */
    time2str: (stamp, opt_short) => {
        let m = Math.floor(stamp / 60);
        let h = Math.floor(m / 60);

        const chunks = [(m % 60).pad(1 + !!h)];
        opt_short || chunks.push((stamp % 60).pad());
        h && chunks.unshift(h.pad(1));

        return chunks.join(':');
    },
});
